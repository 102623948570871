import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import BackupPoliciesWrapper from './backup_policies/backupPoliciesWrapper';
import backupRequest from '../../_network/backup_request';
import Constants from '../../config/constants';
import { backupServiceURLs } from '../../_network/apiUrls';
import BackupAutomationStart from './backupAutomationStart';
import { openstackRequest } from '../../_network/openstack_request';
import { openStackServices } from '../../config/openStackConstants';
import { identityKeystonConstants } from '../../config/openStackConstants';
import { usersUrl as usersUrlResponses } from '../../_api_responses/openstack/identity/users/v3';
import CustomBackdrop from '../_common/CustomBackdrop';

const SERVICE_NAME = openStackServices.identityService
const DEFAULT_CRED_NAME = "Backup_Automation_Credentials"

const BackupAutomationContent = (props) => {
    const { navigate } = props
    const theme = useTheme()
    const [isLoading, setIsLoading] = useState(true)
    const [serviceEnabled, setServiceEnabled] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject)
    const adminProjects = useSelector(state => state.profile.adminProjects)
    const userID = useSelector(state => state.profile.userID)
    const [accessRules, setAccessRules] = useState([])

    const token = useSelector(state => state.profile.x_auth_token)

    const identityServiceDomain = useSelector(
            state => state.openstack.purchasedServices.filter(
            service => service.service === SERVICE_NAME)[0].config_params.service_domain)
        const identityServiceVersion = useSelector(
            state => state.openstack.purchasedServices.filter(
            service => service.service === SERVICE_NAME)[0].config_params.api_version)
        const usersUrl = useSelector(
            state => state.identityKeystone.identityKeystoneApiUrls.filter(
                version => version.api_version === "v3")[0].urls.filter(
                    url => url.keyword === identityKeystonConstants.usersUrl)[0].url)

    const getProjectName = () => {
        const project_object = adminProjects.find(item => item.id === defaultAdminProject)
        return project_object ? project_object.name : ''
    }

    const enableBackupAutomationService = async () => {
        setIsLoading(true)
        
        let url = `${identityServiceDomain}/${identityServiceVersion}/${usersUrl}/${userID}/application_credentials`
        const method = "POST"

        let data = {}
        data["name"] = `${DEFAULT_CRED_NAME}_${getProjectName()}`
        data["access_rules"] = [...accessRules]

        const credential_response = await openstackRequest({
            url: url, 
            method: method, 
            data: {application_credential: data},
            token,
        })

        if (credential_response.status_code === usersUrlResponses.post.success_response.status_code) {
            url = `${Constants.backup_service_domain}/${backupServiceURLs.userCredentialsEP}`
            let request_data = {
                url: url,
                method: "POST",
                data: {
                    credential_id: credential_response.data.application_credential.id,
                    credential_secret: credential_response.data.application_credential.secret,
                    credential_name: credential_response.data.application_credential.name
                }
            }
            const response = await backupRequest(request_data)
            if (response.status_code === 201) {
                setServiceEnabled(true)
            }
        } 
        setTimeout(() => {setIsLoading(false)}, 1000)
    }

    useEffect(() => {
        const url = `${Constants.backup_service_domain}/${backupServiceURLs.accessRulesEP}`
        let request_data = {
            url: url,
            method: "GET"
        }
        backupRequest(request_data).then((response) => {
            if (response.status_code === 200) {
                setAccessRules(response.data)
            }
        }).catch((err) => {
            console.log(err)
        })
    },[])

    useEffect(() => {
        if (isLoading && !serviceEnabled) {
            const url = `${Constants.backup_service_domain}/${backupServiceURLs.userCredentialsEP}`
            let request_data = {
                url: url,
                method: "GET"
            }
            backupRequest(request_data).then((response) => {
                if (response.status_code === 200) {
                    if (response.data.status === "active") {
                        setServiceEnabled(true)
                    } else {
                        setServiceEnabled(false)
                    }
                } else {
                    setServiceEnabled(false)
                }
                setIsLoading(false)
            }).catch((err) => {
                console.log(err)
                setIsLoading(false)
            })
        }
    },[
        isLoading,
        serviceEnabled
    ])

    useEffect(() => {
        setIsLoading(true)
        setServiceEnabled(false)
    },[
        defaultAdminProject
    ])
    
    return (
        <Box sx={{ background: theme.palette.customWhite, height: 'calc(100vh - 64px)' }}>
            {/* <ServiceContentHeader 
                service_title={serviceTitle} 
                service_menu={serviceMenu}
                service_menu_titles={defaultTexts}
                onClick={changeMenuActiveTab}
            /> */}

            { isLoading && <CustomBackdrop open={isLoading} /> }

            {
                !isLoading && !serviceEnabled && 
                <BackupAutomationStart 
                    enableBackupAutomationService={enableBackupAutomationService} 
                />
            }

            {
                !isLoading && serviceEnabled &&
                <BackupPoliciesWrapper                
                    navigate={navigate} 
                />
            }
        </Box>
    )
};

export default BackupAutomationContent;