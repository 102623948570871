import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import WrapperBox from '../../_common/WrapperBox';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Constants from '../../../config/constants';
import { Grid }  from '@mui/material';
import PolicySpecs from './policySpecs';
import PolicyRules from './policyRules';
import CustomSelectField from '../../_common/_form_fields/CustomSelectField';
import { 
    backupPolicyUpdateForm,
    backupPolicyRetentionUpdateForm,
    backupPolicySchedulerUpdateForm,
    backupPolicyOperatorForm,
    backupPolicyTargetForm } from '../../../_data/backup_automation/backup_data';
import CustomDialog from '../../_common/CustomDialog';
import FormGroup from '@mui/material/FormGroup';
import { getFormFieldComponent } from '../../_common/_form_fields/form_helpers';
import CustomCheckboxField from '../../_common/_form_fields/CustomCheckboxField';
import PlusButton from '../../_common/PlusButton';
import MinusButton from '../../_common/MinusButton';
import CustomText from '../../_common/CustomText';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { backupServiceURLs } from '../../../_network/apiUrls';
import backupRequest from '../../../_network/backup_request';
import { isValidCron } from 'cron-validator';
import { useTheme } from '@mui/material';
import ServiceCardContentHeader from '../../_common/ServiceCardContentHeader';

const BackupPolicyDetail = (props) => {
    const { selectedRow, policyData, selectedPolicy, handleDataFetch } = props
    
    const [isCardLoading, setIsCardLoading] = useState(true)
    const { handleDelete, setSelectedRow, isSuspended, setSuspendedDialogOpen } = props
    const theme = useTheme()
    
    const mode = useSelector(state => state.settings.uiMode)
    const defaultTexts = useSelector(state => state.texts.langTexts);
    const [policyActions, setPolicyActions] = useState([])
    const [currentAction, setCurrentAction] = useState("")
    const [updatePolicyDialogOpen, setUpdatePolicyDialogOpen] = useState(false)
    const [policyUpdateData, setPolicyUpdateData] = useState({})
    const [updatePolicyRetentionDialogOpen, setUpdatePolicyRetentionDialogOpen] = useState(false)
    const [policyRetentionUpdateData, setPolicyRetentionUpdateData] = useState({})
    const [updatePolicySchedulerDialogOpen, setUpdatePolicySchedulerDialogOpen] = useState(false)
    const [policySchedulerUpdateData, setPolicySchedulerUpdateData] = useState({...selectedPolicy.params})
    const [updatePolicyRulesDialogOpen, setUpdatePolicyRulesDialogOpen] = useState(false)
    const [policyRulesOperator, setPolicyRulesOperator] = useState(selectedPolicy.resource.policy_type)
    const [policyRules, setPolicyRules] = useState([...selectedPolicy.resource.policy_rules])
    const [weekdaysForm, setWeekdaysForm] = useState([])
    const [error, setError] = useState()
    const [successBackupPolicyUpdate, setSuccessBackupPolicyUpdate] = useState()
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);

    const drawerOpened = useSelector(state => state.drawer.drawerOpened)	
    const drawerWidth = drawerOpened ? 270 : 65

    const [policySubMenu, setPolicySubMenu] = useState(selectedPolicy.resource.policy_rules.length > 0 ? [
        {keyword: "submenuDetails", navigation: "/policy-details", is_active: true},
        {keyword: "submenuMatchingRules", navigation: "/policy-rules", is_active: false}
    ] : [
        {keyword: "submenuDetails", navigation: "/policy-details", is_active: true}
    ]
    )

    const weekDays = [
        defaultTexts.sundayFormFieldLabel,
        defaultTexts.mondayFormFieldLabel,
        defaultTexts.tuesdayFormFieldLabel,
        defaultTexts.wednesdayFormFieldLabel,
        defaultTexts.thursdayFormFieldLabel,
        defaultTexts.fridayFormFieldLabel,
        defaultTexts.saturdayFormFieldLabel
    ]

    const [currentTab, setCurrentTab] = useState("/policy-details")

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const handleSuccessDialogClose = () => {
        setSuccessBackupPolicyUpdate(null);
        setSuccessDialogOpen(false);
    }

    const handlePolicyDetailTabChange = useCallback((navigation) => {
        let newPolicySubmenuData = policySubMenu.map(item => {
            if (item.navigation === navigation) {
                item.is_active = true
            } else {
                item.is_active = false
            }
            return item
        })
        setPolicySubMenu(newPolicySubmenuData)
        setCurrentTab(navigation)
    },[
        policySubMenu
    ])

    const handlePolicyUpdateDataChange = (event,field_key) => {
        let new_data = {...policyUpdateData}
        for (let n in backupPolicyUpdateForm) {
            if (backupPolicyUpdateForm[n].field_key === field_key) {
                if (backupPolicyUpdateForm[n].field_type === "string") {
                    new_data[field_key] = event.target.value
                } else if (backupPolicyUpdateForm[n].field_type === "bool") {
                    new_data[field_key] = event.target.checked
                } else {
                    new_data[field_key] = event
                }
            }
        }
        setPolicyUpdateData(new_data)
    }

    const handlePolicyRetentionUpdateDataChange = (event,field_key) => {
        let new_data = {...policyRetentionUpdateData}
        for (let n in backupPolicyRetentionUpdateForm) {
            if (backupPolicyRetentionUpdateForm[n].field_key === field_key) {
                if (backupPolicyRetentionUpdateForm[n].field_type === "string") {
                    new_data[field_key] = event.target.value
                } else if (backupPolicyRetentionUpdateForm[n].field_type === "integer") {
                    new_data[field_key] = parseInt(event.target.value)
                } else {
                    new_data[field_key] = event
                }
            }
        }
        setPolicyRetentionUpdateData(new_data)
    }

    const handlePolicySchedulerUpdateDataChange = (event,field_key) => {
        let new_data = {...policySchedulerUpdateData}
        for (let n in backupPolicySchedulerUpdateForm) {
            if (backupPolicySchedulerUpdateForm[n].field_key === field_key) {
                if (backupPolicySchedulerUpdateForm[n].field_type === "string") {
                    new_data[field_key] = event.target.value
                } else if (backupPolicySchedulerUpdateForm[n].field_type === "integer") {
                    new_data[field_key] = parseInt(event.target.value)
                } else {
                    new_data[field_key] = event
                }
            }
        }
        setPolicySchedulerUpdateData(new_data)
    }

    const handleWeekdaysFormDataChange = (event,field_key) => {
        let new_list = [...weekdaysForm]
        if (event.target.checked) {
            new_list.push(field_key)
        } else {
            new_list = new_list.filter(item => item !== field_key)
        }
        setWeekdaysForm(new_list.sort())
    }

    const handleTargetMatchingPolicyFormDataChange = (event,field_key,index) => {
        let new_form_data = [...policyRules]
        if (backupPolicyTargetForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[index][field_key] = event.target.checked
        } else if (backupPolicyTargetForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[index][field_key] = event
        } else {
            new_form_data[index][field_key] = event.target.value
        }
        setPolicyRules(new_form_data)
    }

    const handleTargetMatchingPolicyRemove = () => {
        let new_data = [...policyRules]
        new_data.pop()
        setPolicyRules(new_data)
    }

    const handleTargetMatchingPolicyAdd = () => {
        let new_form_data = {}
        for (const n in backupPolicyTargetForm) {
            if (
                backupPolicyTargetForm[n].field_type === "string" || 
                backupPolicyTargetForm[n].field_type === "select"
                ) {
                new_form_data[backupPolicyTargetForm[n].field_key] = backupPolicyTargetForm[n].default_value ? 
                backupPolicyTargetForm[n].default_value : ""
            } else if (backupPolicyTargetForm[n].field_type === "bool") {
                new_form_data[backupPolicyTargetForm[n].field_key] = backupPolicyTargetForm[n].default_value ? 
                backupPolicyTargetForm[n].default_value : 
                false
            }
        }
        const updated_data = [...policyRules]
        updated_data.push(new_form_data)
        setPolicyRules(updated_data)
    }

    const getPolicyUpdateForm = () => {
        let form = [...backupPolicyUpdateForm]
        if (selectedPolicy.type === "standard") {
            form = form.filter(item => item.field_key !== "cron")
        }
        return (
            <FormGroup>
                {form.map(field => {
                    let new_field = {...field}
                    delete new_field.label
                    return (
                        getFormFieldComponent(
                            field,
                            policyUpdateData,
                            handlePolicyUpdateDataChange,
                            defaultTexts[field.label],
                            {
                                ...new_field,
                                item_titles: defaultTexts
                            }
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const getPolicyRetentionUpdateForm = () => {
        let form = [...backupPolicyRetentionUpdateForm]
        if (policyRetentionUpdateData.type === "count") {
            form = form.filter(item => item.field_key !== "time")
        }
        return (
            <FormGroup>
                {form.map(field => {
                    let new_field = {...field}
                    delete new_field.label
                    return (
                        getFormFieldComponent(
                            field,
                            policyRetentionUpdateData,
                            handlePolicyRetentionUpdateDataChange,
                            defaultTexts[field.label],
                            {
                                ...new_field,
                                item_titles: defaultTexts
                            }
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const getPolicySchedulerUpdateForm = () => {
        let form = [...backupPolicySchedulerUpdateForm]
        if (policySchedulerUpdateData.frequency !== "daily") {
            form = form.filter(item => item.field_key !== "hours")
        }
        if (policySchedulerUpdateData.frequency !== "monthly") {
            form = form.filter(item => item.field_key !== "dates")
        }
        if (policySchedulerUpdateData.frequency !== "yearly") {
            form = form.filter(item => item.field_key !== "months" && item.field_key !== "monthday")
        }
        return (
            <FormGroup>
                {form.map(field => {
                    let new_field = {...field}
                    delete new_field.label
                    return (
                        getFormFieldComponent(
                            field,
                            policySchedulerUpdateData,
                            handlePolicySchedulerUpdateDataChange,
                            defaultTexts[field.label],
                            {
                                ...new_field,
                                item_titles: defaultTexts
                            }
                        )
                    )
                })}
                {policySchedulerUpdateData.frequency === "weekly" &&
                    <Grid container spacing={0} sx={{ml: 1, my: 2}}>
                        {weekDays.map((day,index) => {
                            return (
                                <Grid item key={index}>
                                    <CustomCheckboxField 
                                        currentValue={weekdaysForm.includes(index)} 
                                        setCurrentValue={handleWeekdaysFormDataChange}
                                        label={day}
                                        required={false}
                                        field_key={index}
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                }
            </FormGroup>
        )
    }

    const getPolicyRulesUpdateForm = () => {
        return (
            <FormGroup>
                {policyRules.length > 1 && 
                    backupPolicyOperatorForm.map(field => {
                        let form_field_options = {...field}
                        delete form_field_options.label
                        form_field_options["item_titles"] = defaultTexts
                        const data = {}
                        data[field.field_key] = policyRulesOperator
                        return (
                            getFormFieldComponent(
                                field,
                                data,
                                setPolicyRulesOperator,
                                defaultTexts[field.label],
                                {
                                    sx: {m: 1, width: "90%"},
                                    ...form_field_options
                                }
                            )
                        )
                    })
                }
                {policyRules.map((pol,index) => {
                    let form = [...backupPolicyTargetForm]
                    if (pol.field !== "metadata") {
                        form = form.filter(item => item.field_key !== "key")
                    }
                    return (
                        <Box key={index} sx={{mt: 3}}>
                            <CustomText sx={{m:1}}>{defaultTexts.targetMatchingPolicyFormFieldLabel} {index + 1}</CustomText>
                            {form.map(item => {
                                let update_item = {...item}
                                delete update_item.label
                                if (item.field_key === "key") {
                                    update_item.helpText = defaultTexts[item.helpText]
                                    update_item["sx"] = {m: 1, width: "98%"}
                                }
                                return (
                                    getFormFieldComponent(
                                        item,
                                        pol,
                                        handleTargetMatchingPolicyFormDataChange,
                                        defaultTexts[item.label],
                                        {  
                                            index: index,
                                            sx: {m: 1, width: "90%"},
                                            ...update_item,
                                            item_titles: defaultTexts,
                                        }
                                    )
                                )
                            })}
                        </Box>
                    )
                    })}
                    {policyRules.length > 0 && <MinusButton 
                        style={{ transform: 'scale(0.7)' }}
                        sx={{height: 5, m: 1}}
                        onClick={handleTargetMatchingPolicyRemove}
                    />}
                    {<Stack 
                        direction="row" 
                        spacing={2} 
                        alignItems="center"
                        onClick={handleTargetMatchingPolicyAdd}
                        sx={{cursor: "pointer", m: 1}}
                    >
                        <PlusButton 
                            style={{ transform: 'scale(0.7)' }}
                            sx={{height: 5}}
                            onClick={handleTargetMatchingPolicyAdd}
                        />
                        <CustomText>
                            {defaultTexts.addTargetMatchingPolicyFormFieldLabel}
                        </CustomText>
                    </Stack>}
            </FormGroup>
        )
    }

    const handleConfirmDeleteDialogOpen = () => {
        handleDelete([selectedRow.id])
    }

    const handleUpdatePolicyDialogOpen = useCallback(() => {
        if (isSuspended) {
			setSuspendedDialogOpen(true)
		} else {
			setUpdatePolicyDialogOpen(true)
		}
    }, [isSuspended, setSuspendedDialogOpen])

    const handleUpdatePolicyDialogClose = () => {
        setPolicyUpdateData({})
        setUpdatePolicyDialogOpen(false)
    }

    const validateCronExpression = (expression) => {
        return isValidCron(expression, { alias: true, seconds: false }); // Configure options as needed
    };

    const onPolicyUpdate = async () => {
        let data = {}
        data["name"] = policyUpdateData.name
        data["enabled"] = policyUpdateData.enabled
        if (selectedPolicy.type === "custom" && policyUpdateData.cron !== selectedPolicy.cron) {
            const valid_cron = validateCronExpression(policyUpdateData.cron)
            if (valid_cron) {
                data["cron"] = policyUpdateData.cron
            }
        }
        data["resource"] = {...selectedPolicy.resource}
        data["resource"]["type"] = policyUpdateData.target_type
        handleUpdatePolicyDialogClose()
        const res = await onBackupPolicyUpdate(data)
        if (res) {
            let new_form_data = {}
            let form = [...backupPolicyUpdateForm]
            if (res.type === "standard") {
                form = form.filter(item => item.field_key !== "cron")
            }
            for (const n in form) {
                if (form[n].field_key === "target_type") {
                    new_form_data[form[n].field_key] = res.resource.type
                } else {
                    new_form_data[form[n].field_key] = res[form[n].field_key]
                }
            }
            setPolicyUpdateData(new_form_data)
        }
    }

    const handleUpdatePolicyRetentionDialogOpen = useCallback(() => {
        if (isSuspended) {
			setSuspendedDialogOpen(true)
		} else {
			setUpdatePolicyRetentionDialogOpen(true)
		}
    }, [isSuspended, setSuspendedDialogOpen])

    const handleUpdatePolicyRetentionDialogClose = () => {
        setPolicyRetentionUpdateData({})
        setUpdatePolicyRetentionDialogOpen(false)
    }

    const onPolicyRetentionUpdate = async () => {
        let data = {}
        data["retention"] = {}
        data["retention"]["type"] = policyRetentionUpdateData.type
        data["retention"]["count"] = policyRetentionUpdateData.count
        if (policyRetentionUpdateData.type === "age") {
            data["retention"]["time"] = policyRetentionUpdateData.time
        }
        handleUpdatePolicyRetentionDialogClose()
        const res = await onBackupPolicyUpdate(data)
        if (res) {
            let new_form_data = {}
            let form = [...backupPolicyRetentionUpdateForm]
            if (res.retention.type === "count") {
                form =form.filter(item => item.field_key !== "time")
            }
            for (const n in form) {
                new_form_data[form[n].field_key] = res.retention[form[n].field_key]
            }
            setPolicyRetentionUpdateData(new_form_data)
        }
    }

    const handleUpdatePolicySchedulerDialogOpen = useCallback(() => {
        if (isSuspended) {
			setSuspendedDialogOpen(true)
		} else {
			setUpdatePolicySchedulerDialogOpen(true)
		}
    }, [isSuspended, setSuspendedDialogOpen])

    const handleUpdatePolicySchedulerDialogClose = () => {
        setPolicySchedulerUpdateData({...selectedPolicy.params})
        setUpdatePolicySchedulerDialogOpen(false)
    }

    const onPolicySchedulerUpdate = async () => {
        const frequency = policySchedulerUpdateData.frequency
        const starting_at = policySchedulerUpdateData.starting_at
        let every = null
        let monthday = null
        let weekdays = weekdaysForm

        if (frequency === "yearly") {
            every = policySchedulerUpdateData.months ? parseInt(policySchedulerUpdateData.months) : 
            backupPolicySchedulerUpdateForm.filter(item => item.field_key === "months")[0].default_value
            monthday = policySchedulerUpdateData.monthday ? policySchedulerUpdateData.monthday : 
            backupPolicySchedulerUpdateForm.filter(item => item.field_key === "monthday")[0].default_value
        } else if (policySchedulerUpdateData.frequency === "monthly") {
            every = parseInt(policySchedulerUpdateData.dates) ? policySchedulerUpdateData.dates :
            backupPolicySchedulerUpdateForm.filter(item => item.field_key === "months")[0].default_value
        } else if (policySchedulerUpdateData.frequency === "weekly") {
            weekdays = weekdaysForm
        } else {
            if (policySchedulerUpdateData.hours) {
                every = parseInt(policySchedulerUpdateData.hours)
            }
        }

        let data = {}
        data["params"] = {}
        data["params"]["frequency"] = frequency

        if (policySchedulerUpdateData.frequency === "yearly") {
            data["params"]["every"] = every
            data["params"]["monthday"] = monthday
        } else if (policySchedulerUpdateData.frequency === "monthly") {
            data["params"]["every"] = every
        } else if (policySchedulerUpdateData.frequency === "weekly") {
            data["params"]["weekdays"] = weekdays
        } else {
            if (policySchedulerUpdateData.hours) {
                data["params"]["every"] = every
            }
        }
        data["params"]["starting_at"] = starting_at

        let cron_parts = ["0","*","*","*","*"]
        cron_parts[1] = starting_at

        if (policySchedulerUpdateData.frequency === "daily") {
            if (policySchedulerUpdateData.hours) {
                if (policySchedulerUpdateData.starting_at !== "0") {
                    cron_parts[1] = `${policySchedulerUpdateData.starting_at}-23/${policySchedulerUpdateData.hours}`
                } else {
                    cron_parts[1] = `*/${policySchedulerUpdateData.hours}`
                }
                
            } else {
                cron_parts[1] = policySchedulerUpdateData.starting_at
            }
        } else if (policySchedulerUpdateData.frequency === "weekly") {
            cron_parts[4] = weekdaysForm.join(",")
        } else if (policySchedulerUpdateData.frequency === "monthly") {
            if (!every) {
                every = backupPolicySchedulerUpdateForm.filter(item => item.field_key === "dates")[0].default_value
            }
            if (every === "32") {
                cron_parts[2] = "L"
            } else {
                cron_parts[2] = every
            }
            
        } else {
            if (!every) {
                every = backupPolicySchedulerUpdateForm.filter(item => item.field_key === "months")[0].default_value
            }
            cron_parts[3] = every
            if (!monthday) {
                monthday = backupPolicySchedulerUpdateForm.filter(item => item.field_key === "monthday")[0].default_value
            }
            if (monthday === "first") {
                cron_parts[2] = 1
            } else {
                cron_parts[2] = "L"
            }
        }
        data["cron"] = cron_parts.join(" ")
        
        handleUpdatePolicySchedulerDialogClose()
        const res = await onBackupPolicyUpdate(data)
        if (res) {
            let new_form_data = {...res.params}
            setPolicySchedulerUpdateData(new_form_data)

            if (res.params.frequency === "weekly") {
                setWeekdaysForm(res.params.weekdays)
            }
        }
    }

    const handleUpdatePolicyRulesDialogOpen = useCallback(() => {
        if (isSuspended) {
			setSuspendedDialogOpen(true)
		} else {
			setUpdatePolicyRulesDialogOpen(true)
		}
    }, [isSuspended, setSuspendedDialogOpen])

    const handleUpdatePolicyRulesDialogClose = () => {
        setPolicyRules([...selectedPolicy.resource.policy_rules])
        setPolicyRulesOperator(selectedPolicy.resource.policy_type)
        setUpdatePolicyRulesDialogOpen(false)
    }

    const onPolicyRulesUpdate = async () => {
        let data = {}
        data["resource"] = {}
        data["resource"]["type"] = selectedPolicy.resource.type
        data["resource"]["policy_type"] = policyRulesOperator
        data["resource"]["policy_rules"] = policyRules
        handleUpdatePolicyRulesDialogClose()
        await onBackupPolicyUpdate(data)
    }

    const onBackupPolicyUpdate = async (data) => {
        let result = null
        const url = `${Constants.backup_service_domain}/${backupServiceURLs.backupPoliciesEP}/${selectedRow.id}`
        const method = "PUT"
        
        const response = await backupRequest({
            url:url, 
            method:method, 
            data: data
        })
        if (response.status_code === 200) {
            result = response.data
            handleDataFetch()
            setSuccessBackupPolicyUpdate({
                success_title: 'backupPolicyUpdateSuccessTitle', 
                success_message: 'backupPolicyUpdateSuccessMessage'
            })
        } else {
            const errorObject = {
                error_title: 'backupPolicyUpdateErrorTitle', 
                error_message: 'backupPolicyUpdateErrorMessage',
                error_details: response.error
            }
            setError(errorObject)
        }
        return result
    }

    useEffect(() => {
        setTimeout(() => setIsCardLoading(false), 600)
    },[])

    useEffect(() => {
            setErrorDialogOpen(true)
        },[error]);
    
    useEffect(() => {
        setSuccessDialogOpen(true)
    },[successBackupPolicyUpdate]);

    useEffect(() => {
        let policy_actions = []
        let new_action = {}
        new_action["value"] = "policy_update"
        new_action["action"] = handleUpdatePolicyDialogOpen
        new_action["keyword"] = "backupPolicyUpdateActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        policy_actions.push({...new_action})

        new_action = {}
        new_action["value"] = "retention_update"
        new_action["action"] = handleUpdatePolicyRetentionDialogOpen
        new_action["keyword"] = "backupPolicyRetentionUpdateActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        policy_actions.push({...new_action})

        if (selectedPolicy.type === "standard") {
            new_action = {}
            new_action["value"] = "scheduler_update"
            new_action["action"] = handleUpdatePolicySchedulerDialogOpen
            new_action["keyword"] = "backupPolicySchedulerUpdateActionTitle"
            new_action["button_text"] = "selectButtonTitleText"
            policy_actions.push({...new_action})
        }
        
        new_action = {}
        new_action["value"] = "rules_update"
        new_action["action"] = handleUpdatePolicyRulesDialogOpen
        new_action["keyword"] = "backupPolicyRulesUpdateActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        policy_actions.push({...new_action})

        setPolicyActions(policy_actions)
    },[
        policyData,
        selectedPolicy,
        handleUpdatePolicyDialogOpen,
        handleUpdatePolicyRetentionDialogOpen,
        handleUpdatePolicySchedulerDialogOpen,
        handleUpdatePolicyRulesDialogOpen,
    ])

    useEffect(() => {
        if (Object.keys(policyUpdateData).length === 0) {
            let new_form_data = {}
            let form = [...backupPolicyUpdateForm]
            if (selectedPolicy.type === "standard") {
                form = form.filter(item => item.field_key !== "cron")
            }
            for (const n in form) {
                if (form[n].field_key === "target_type") {
                    new_form_data[form[n].field_key] = selectedPolicy.resource.type
                } else {
                    new_form_data[form[n].field_key] = selectedPolicy[form[n].field_key]
                }
            }
            setPolicyUpdateData(new_form_data)
        }
    },[policyUpdateData, selectedPolicy]);

    useEffect(() => {
        if (Object.keys(policyRetentionUpdateData).length === 0) {
            let new_form_data = {}
            let form = [...backupPolicyRetentionUpdateForm]
            if (selectedPolicy.retention.type === "count") {
                form =form.filter(item => item.field_key !== "time")
            }
            for (const n in form) {
                new_form_data[form[n].field_key] = selectedPolicy.retention[form[n].field_key]
            }
            setPolicyRetentionUpdateData(new_form_data)
        }
    },[policyRetentionUpdateData, selectedPolicy]);


    useEffect(() => {
        if (selectedPolicy.params.frequency === "weekly") {
            setWeekdaysForm(selectedPolicy.params.weekdays)
        }
    },[selectedPolicy]);


    useEffect(() => {
        if (selectedPolicy.resource.policy_rules.length > 0) {
            setPolicyRules([...selectedPolicy.resource.policy_rules])
            setPolicySubMenu([
                {keyword: "submenuDetails", navigation: "/policy-details", is_active: currentTab === "/policy-details" ? true : false},
                {keyword: "submenuMatchingRules", navigation: "/policy-rules", is_active: currentTab === "/policy-rules" ? true : false}
            ]) 
        } else {
            setPolicyRules([])
            setPolicySubMenu(
                [
                    {keyword: "submenuDetails", navigation: "/policy-details", is_active: true}
                ]
            )
        }
    },[selectedPolicy, currentTab])


    return (
        <React.Fragment>

            {
				selectedRow !== null && 
				<WrapperBox>
					<ServiceCardContentHeader 
						service_menu={policySubMenu}
						service_menu_titles={defaultTexts}
						onClick={handlePolicyDetailTabChange}
						setSelectedRow={setSelectedRow}
						setSelectedId={setSelectedRow ? setSelectedRow.id : ''}
					/>
				</WrapperBox>
			}

            <Box sx={{
				padding: ' 0px 20px 80px 20px',
				background: mode === 'light' ? theme.palette.customGrayLight : undefined,
				borderTop: `${theme.palette.customGrayDark} 1px solid`,
			}}>
                {currentTab === "/policy-details" &&
                    <PolicySpecs 
                        policyData={selectedPolicy}
                    />
                }
                {currentTab === "/policy-rules" && 
                    <PolicyRules
                        policyRules={selectedPolicy.resource.policy_rules}
                        ruleOperator={selectedPolicy.resource.policy_type}
                    />
                }
            </Box>
        
            {
            !isCardLoading && <Paper sx={{ 
                    position: 'fixed',
                    width: `calc(100% - ${drawerWidth}px)`,
                    bottom: 0, 
                    height: '60px',
                    left: `${drawerWidth}px`,
                    borderTop: `${theme.palette.customGrayDark} 2px solid`,
                    borderRadius: '0px',
                    padding: '0px 20px 0px 12px',
                }} 
            >
                <Grid 
                    container 
                    alignItems="center"  
                    justifyContent="space-between"
                >
                    <Grid item>
                        {policyActions && <CustomSelectField 
                            items={policyActions}
                            currentValue={currentAction}
                            setCurrentValue={setCurrentAction}
                            item_titles={defaultTexts}
                            label={defaultTexts.actionsDropdownLabelText}
                            empty={true}
                            size="small"
                            sx={{m: 1}}
                        />}
                        {currentAction.length > 0 && 
                            <Button 
                                    variant="contained"
                                    color="secondary"
                                    sx={{m: 1, height: '70%'}}
                                    onClick={policyActions.filter(
                                        action => action.value === currentAction)[0].action
                                    }
                                >
                                {defaultTexts[policyActions.filter(
                                    action => action.value === currentAction)[0].button_text]}
                            </Button>
                        }
                    </Grid>
                    <Grid item>
                        {selectedRow !== null && 
                            <IconButton onClick={handleConfirmDeleteDialogOpen}>
                                <DeleteIcon 
                                    color="error"
                                />
                            </IconButton>}
                    </Grid>
                </Grid>
            </Paper>
            }

            <CustomDialog
                open={updatePolicyDialogOpen}
                onClose={handleUpdatePolicyDialogClose}
                dialogTitle={{
                    title: defaultTexts.updateBackupPolicyActionTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: "", 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.submitButtonText, 
                    onClick: onPolicyUpdate, 
                    sx: {color: 'primary.main'}}]}
            >
                {getPolicyUpdateForm()}
            </CustomDialog>

            <CustomDialog
                open={updatePolicyRetentionDialogOpen}
                onClose={handleUpdatePolicyRetentionDialogClose}
                dialogTitle={{
                    title: defaultTexts.updateBackupPolicyRetentionActionTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: "", 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.submitButtonText, 
                    onClick: onPolicyRetentionUpdate, 
                    sx: {color: 'primary.main'}}]}
            >
                {getPolicyRetentionUpdateForm()}
            </CustomDialog>

            <CustomDialog
                open={updatePolicySchedulerDialogOpen}
                onClose={handleUpdatePolicySchedulerDialogClose}
                dialogTitle={{
                    title: defaultTexts.updateBackupPolicySchedulerActionTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: "", 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.submitButtonText, 
                    onClick: onPolicySchedulerUpdate, 
                    sx: {color: 'primary.main'}}]}
            >
                {getPolicySchedulerUpdateForm()}
            </CustomDialog>

            <CustomDialog
                open={updatePolicyRulesDialogOpen}
                onClose={handleUpdatePolicyRulesDialogClose}
                dialogTitle={{
                    title: defaultTexts.updateBackupPolicyRulesActionTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: "", 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.submitButtonText, 
                    onClick: onPolicyRulesUpdate, 
                    sx: {color: 'primary.main'}}]}
            >
                {getPolicyRulesUpdateForm()}
            </CustomDialog>

            {successBackupPolicyUpdate && <CustomDialog
                open={successDialogOpen}
                onClose={handleSuccessDialogClose}
                dialogTitle={{
                    title: defaultTexts[successBackupPolicyUpdate.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts[successBackupPolicyUpdate.success_message], 
                    sx: {color: 'text.primary'}}}
            />}

            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
        </React.Fragment>
    )
};

export default BackupPolicyDetail;