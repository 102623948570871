import React from 'react'
import { Route, Routes } from "react-router-dom"
import HomeWrapper from './components/home/components/homeWrapper'
import ComputeNovaWrapper from './components/compute_nova/components/computeNovaWrapper'
import VolumesCinderWrapper from './components/volumes_cinder/components/volumesCinderWrapper'
import ImagesGlanceWrapper from './components/images_glance/components/imagesGlanceWrapper'
import NetworksNeutronWrapper from './components/networks_neutron/components/networksNeutronWrapper'
import DNSDesignateWrapper from './components/dns_designate/components/dnsDesignateWrapper'
import BackupAutomationWrapper from './components/backup_automation/backupAutomationWrapper'
import LimitsWrapper from './components/limits/components/LimitsWrapper'
import PaymentsWrapper from './components/billing/components/PaymentsWrapper'
import BillingWrapper from './components/billing/components/BillingWrapper'
import CustomerSupportWrapper from './components/support/CustomerSupportWrapper'
import useAutoLogout from './components/_common/_hooks/sessionAutoLogout'

const AppRoutes = (props) => {
  useAutoLogout()

  return (
    <Routes>
      <Route exact path="/" element={<HomeWrapper />} />  
      <Route exact path="/compute/instances" element={<ComputeNovaWrapper section="/instances" />} />
      <Route exact path="/compute/keypairs" element={<ComputeNovaWrapper section="/keypairs" />} />
      <Route exact path="/storage/volumes" element={<VolumesCinderWrapper section='/volumes' />} />
      <Route exact path="/storage/transfers" element={<VolumesCinderWrapper section='/transfers' />} />
      <Route exact path="/storage/snapshots" element={<VolumesCinderWrapper section='/snapshots' />} />
      <Route exact path="/storage/backups" element={<VolumesCinderWrapper section='/backups' />} />
      <Route exact path="/storage/volume-groups" element={<VolumesCinderWrapper section='/volume-groups' />} />
      <Route exact path="/storage/group-snapshots" element={<VolumesCinderWrapper section='/group-snapshots' />} />
      <Route exact path="/images" element={<ImagesGlanceWrapper />} />
      <Route exact path="/networks/l2-networking/networks" element={<NetworksNeutronWrapper section="/l2-networking" subsection='/networks' />} />
      <Route exact path="/networks/l2-networking/ports" element={<NetworksNeutronWrapper section="/l2-networking" subsection='/ports' />} />
      <Route exact path="/networks/l2-networking/trunks" element={<NetworksNeutronWrapper section="/l2-networking" subsection='/trunks' />} />
      <Route exact path="/networks/l3-networking/subnets" element={<NetworksNeutronWrapper section="/l3-networking" subsection='/subnets' />} />
      <Route exact path="/networks/l3-networking/floating-ips" element={<NetworksNeutronWrapper section="/l3-networking" subsection='/floating-ips' />} />
      <Route exact path="/networks/l3-networking/virtual-ips" element={<NetworksNeutronWrapper section="/l3-networking" subsection='/virtual-ips' />} />
      <Route exact path="/networks/l3-networking/routers" element={<NetworksNeutronWrapper section="/l3-networking" subsection='/routers' />} />
      <Route exact path="/networks/network-security/security-groups" element={<NetworksNeutronWrapper section="/network-security" subsection='/security-groups' />} />
      <Route exact path="/networks/network-security/address-groups" element={<NetworksNeutronWrapper section="/network-security" subsection='/address-groups' />} />
      <Route exact path="/networks/network-fwaas/firewall-groups" element={<NetworksNeutronWrapper section="/network-fwaas" subsection='/firewall-groups' />} />
      <Route exact path="/networks/network-fwaas/firewall-policies" element={<NetworksNeutronWrapper section="/network-fwaas" subsection='/firewall-policies' />} />
      <Route exact path="/networks/network-fwaas/firewall-rules" element={<NetworksNeutronWrapper section="/network-fwaas" subsection='/firewall-rules' />} />
      <Route exact path="/networks/network-vpnaas/ike-policies" element={<NetworksNeutronWrapper section="/network-vpnaas" subsection='/ike-policies' />} />
      <Route exact path="/networks/network-vpnaas/ipsec-policies" element={<NetworksNeutronWrapper section="/network-vpnaas" subsection='/ipsec-policies' />} />
      <Route exact path="/networks/network-vpnaas/vpn-services" element={<NetworksNeutronWrapper section="/network-vpnaas" subsection='/vpn-services' />} />
      <Route exact path="/networks/network-vpnaas/endpoint-groups" element={<NetworksNeutronWrapper section="/network-vpnaas" subsection='/endpoint-groups' />} />
      <Route exact path="/networks/network-vpnaas/site-connections" element={<NetworksNeutronWrapper section="/network-vpnaas" subsection='/site-connections' />} />
      <Route exact path="/dns/zones" element={<DNSDesignateWrapper section="/zones" />} />
      <Route exact path="/dns/reverse-dns/ptr-records" element={<DNSDesignateWrapper section="/ptr-records" />} />
      <Route exact path="/dns/reverse-dns/floating-ips" element={<DNSDesignateWrapper section="/floating-ips" />} />
      <Route exact path="/backup-automation" element={<BackupAutomationWrapper />} />
      <Route exact path="/limits" element={<LimitsWrapper />} />
      <Route exact path="/billing" element={<BillingWrapper />} />
      <Route exact path="/payments" element={<PaymentsWrapper />} />
      <Route exact path="/support" element={<CustomerSupportWrapper />} />
      <Route path='*' element={<HomeWrapper />} />
    </Routes>
  )
}

export default AppRoutes