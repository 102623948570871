import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomTable from '../../_common/CustomTable';
import { backupPolicyDataSchema } from '../../../_data/backup_automation/backup_data';
import { setBackupPoliciesTableConfig } from '../../../store/reducers/backupAutomationSlice';

const BackupPoliciesTable = (props) => {
    const { policiesData } = props
    const { handleRowSelection } = props
    const { currentAction } = props
    const { setCurrentAction }  = props
    const { actionsTexts }  = props
    const { actionsList } = props
    const { setSelectedIds, deselectAll, setDeselectAll, isSuspended } = props
    
    const defaultTexts = useSelector(state => state.texts.langTexts);
    
    const policiesTableConfig = useSelector(
        state => state.backupAutomation.backupPoliciesTableConfig)
    const rowsPerPage = useSelector(
        state => state.backupAutomation.backupPoliciesTableConfig.rows_per_page)
    const backupPoliciesTableColumns = useSelector(
        state => state.backupAutomation.backupPoliciesTableConfig.columns)
    
    const identityField = backupPoliciesTableColumns.filter(
        item => item.is_id_field)[0].field_key

    const dispatch = useDispatch();

    const handleBackupPolicyTableConfig = (value_list) => {
        const newTableConfig = backupPolicyDataSchema.filter(
            n => value_list.includes(n.field_key))
        let new_data = {...policiesTableConfig}
        new_data.columns = [...newTableConfig]
        dispatch(setBackupPoliciesTableConfig({
            backupPoliciesTableConfig: new_data
        }))
    }
    
    const handleRowsPerPageChange = (rows_num) => {
        let new_data = {...policiesTableConfig}
        new_data.rows_per_page = rows_num
        
        dispatch(setBackupPoliciesTableConfig({
            backupPoliciesTableConfig: new_data
        }))
    }

    return (
        <React.Fragment>
            <CustomTable
                withPagination={true}
                withCheckbox={true}
                withActions={true}
                defaultDataTexts={defaultTexts} 
                tableHeaders={backupPoliciesTableColumns}
                tableHeadersConfigHandler={handleBackupPolicyTableConfig}
                identityField={identityField}
                columns={backupPolicyDataSchema}
                rowsPerPage={rowsPerPage}
                handleRowsPerPageChange={handleRowsPerPageChange}
                dataRows={policiesData}
                handleRowSelection={handleRowSelection}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={actionsTexts}
                actionsList={actionsList}
                setSelectedIds={setSelectedIds}
                deselectAll={deselectAll}
                setDeselectAll={setDeselectAll}
                isSuspended={isSuspended}
            />
        </React.Fragment>
    )
};

export default BackupPoliciesTable;