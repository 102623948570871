const constants =  {
    my_domain: process.env.REACT_APP_MY_DOMAIN,
    language_service_domain: process.env.REACT_APP_LANGUAGE_SERVICE,
    core_service_domain: process.env.REACT_APP_CORE_SERVICE,
    subscription_package_group: process.env.REACT_APP_SUBSCRIPTION_PACKAGE_GROUP,
    config_service_group: process.env.REACT_APP_SERVICE_GROUP,
    config_service_type_frontend: process.env.REACT_APP_SERVICE_TYPE_FRONTEND,
    config_service_type_backend: process.env.REACT_APP_SERVICE_TYPE_BACKEND,
    config_service_custom_admin_ui: process.env.REACT_APP_SERVICE_CUSTOM_ADMIN_UI,
    client_credential_id: process.env.REACT_APP_CLIENT_CREDENTIAL_ID,
    client_credential_secret: process.env.REACT_APP_CLIENT_CREDENTIAL_SECRET,
    client_auth_method: process.env.REACT_APP_CLIENT_AUTH_METHOD,
    auth_server_url: process.env.REACT_APP_AUTH_SERVER_URL,
    google_reCapcha_site_key: process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY,
    google_reCapcha_secret_key: process.env.REACT_APP_GOOGLE_RECAPTCHA_SECRET_KEY,
    billing_url: process.env.REACT_APP_BILLING_URL,
    billing_service_type: process.env.REACT_APP_BILLING_SERVICE_TYPE,
    billing_service_name: process.env.REACT_APP_BILLING_SERVICE_NAME,
    config_service_backup_name: process.env.REACT_APP_BACKUP_SERVICE_NAME,
    backup_service_domain: process.env.REACT_APP_BACKUP_SERVICE,
    backup_service_account_id_header: process.env.REACT_APP_BACKUP_ACCOUNT_ID_HEADER,
    backup_service_project_id_header: process.env.REACT_APP_BACKUP_PROJECT_ID_HEADER,
    backup_service_region_name_header: process.env.REACT_APP_BACKUP_REGION_NAME_HEADER,
    s3URL: process.env.REACT_APP_S3_URL,
    s3Bucket: process.env.REACT_APP_S3_BUCKET,
    google_reCapcha_url: "recaptcha/api/siteverify",
    unAuthorizedStatusCode: 401,  
    simple_filter_type: "simple",
    complex_filter_type: "complex",
    openstack_request_max_retries: 5,
    actions_bar_height: 40,
    defaultDomain: "Default",
    admin_role_name: "admin",
    billing_sections: {
        billing_configs: "billing_configs", 
        billing_services: "billing_services", 
        billing_deposits: "billing_deposits", 
        billing_prices: "billing_prices",
        billing_analytics: "billing_analytics"
    },
    permission_read: "read",
    permission_create: "create",
    permission_update: "update",
    permission_delete: "delete",
    alert_type_project_request: "project_request",
    alert_type_limits_update: "limits_update",
    alert_type_issue_report: "issue_report",
    app_version: "0.1.5",
};

export default constants;