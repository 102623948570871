import Constants from '../../../../config/constants'
import { coreServiceURLs } from '../../../../_network/apiUrls'

export const commonTexts = {
    purchaseServiceTitle: `
    <h3 style="color: #d60c64;">Oops! This service is not purchased.</h3>
    <br>
    `,
    purchaseServiceDescription: `
    <p>If you'd like to access admin user interface for given OpenStack service,</p> 
    <p>you must purchase one of our subscriptions covering this service.</p>
    <br>
    <p>Please, visit our website and choose subscription package, which most fits your needs.</p>   
    <br>
    <br>
    <a href="${Constants.core_frontend_domain}/${coreServiceURLs.getSubscriptionPage}" target="_blank">www.capybara4admin.com</a> 
    `,
    notSupportedServiceVersionNote: "Configured API Version is not supported!",
    selectedRowsLabel: "Selected Rows:",
    rowsPerPageLabel: "Rows per page:",
    totalRowsLabel: "Total Rows",
    deselectAllButtonTooltipText: "Deselect All",
    sortButtonTooltipText: "Sort By",
    addRemoveColumnsButtonTooltipText: "Add/Remove table columns",
    actionsButtonText: "Actions",
    noDataToDisplayText: "No data to display",
    resetAllFiltersTooltipText: "Reset all filters",
    filterOptionsButtonText: "Filter options",
    searchButtonText: "Search",
    filterCustomOptionsDialogTitle: "Customize Filter Options",
    submitButtonText: "Submit",
    successResponseTitle: "Created!",
    successCreateResponseMessage: "Resource was successfully created upon your request.",
    successUpdateTitle: "Updated!",
    successUpdateResponseMessage: "Resource was successfully updated upon your request.",
    successDeleteTitle: "Deleted!",
    successDeleteResponseMessage: "Resource was successfully deleted upon your request.",
    badRequestResponseTitle: "Bad Request!",
    badRequestResponseMessage: "Unfortunately, we could not process your request. It might be due to invalid data provided.",
    forbiddenResponseTitle: "Forbidden!",
    forbiddenResponseMessage: "Unfortunately, you are not allowed to access this data.",
    notFoundResponseTitle: "Not Found!",
    notFoundResponseMessage: "Unfortunately, we could not process your request. It might be due to invalid request URL.",
    conflictResponseTitle: "Conflict!",
    conflictResponseMessage: "Unfortunately, we could not process your request. It might be due to conflict with other service or data.",
    serviceUnavailableResponseTitle: "Service Unavailable!",
    serviceUnavailableResponseMessage: "Unfortunately, we could not process your request. It seems the service is unavailable.",
    unknownResponseTitle: "Unknown Error!",
    unknownResponseMessage: "Unfortunately, we could not process your request.",
    idFormFieldLabel: "ID",
    nameFormFieldLabel: "Name",
    typeFormFieldLabel: "Type",
    urlFormFieldLabel: "URL",
    interfaceFormFieldLabel: "Interface",
    regionFormFieldLabel: "Region",
    usernameFormFieldLabel: "Username",
    descriptionFormFieldLabel: "Description",
    enabledFormFieldLabel: "Enabled",
    secretFormFieldLabel: "Secret",
    unrestrictedFormFieldLabel: "Unrestricted",
    pathFormFieldLabel: "Path",
    serviceFormFieldLabel: "Service",
    methodFormFieldLabel: "Method",
    formValueYes: "Yes",
    formValueNo: "No",
    keyFormFieldLabel: "Key",
    valueFormFieldLabel: "Value",
    optionsFormFieldLabel: "Options",
    tagsFormFieldLabel: "Tags",
    requiredFormFieldError: "This field is required!",
    listFormFieldHelpText: "You can provide more than one tag, separated by comma.",
    domainFormFieldLabel: "Domain",
    parentProjectFormFieldLabel: "Parent",
    projectFormFieldLabel: "Project",
    isDomainFormFieldLabel: "Is Domain",
    defaultProjectFormFieldLabel: "Default Project",
    passwordFormFieldLabel: "Password",
    originalPasswordFormFieldLabel: "Original Password",
    confirmPasswordFormFieldLabel: "Confirm Password",
    passwordMismatchFormFieldError: "Password and confirm password mismatch!",
    doNotAskForPasswordChangeOnFirstUseFormFieldLabel: "Password change on first use",
    passwordNotExpiresFormFieldLabel: "Password never expires",
    doNotLockOutOnFailureAttemptsFormFieldLabel: "Lockout user on failed attemts",
    lockPasswordFromChangeFormFieldLabel: "Lock password from change",
    emailFormFieldLabel: "Email",
    invalidEmailFormFieldError: "Invalid email provided!",
    passwordExpiresAtFormFieldLabel: "Password expires at",
    extraFormFieldLabel: "Extra Fields",
    createTimeFormFieldLabel: "Include create time",
    createTimeHeaderLabel: "Created At",
    parentProjectFormFieldHelpText: "If parent is specified, a new project will be placed in corresponding hierarchy under parent project within the same domain, to which parent belongs.",
    userFormFieldLabel: "User",
    groupFormFieldLabel: "Group",
    roleFormFieldLabel: "Role",
    expiresAtFormFieldLable: "Expires at",
    ruleFormFieldLabel: "Rule",
    expiresAtFormFieldLabel: "Expires At",
    neverFormValue: "Never",
    vcpusFormFieldLabel: "vCPUs",
    ramFormFieldLabel: "RAM (MiB)",
    diskFormFieldLabel: "Disk (GiB)",
    ephemeralDiskFormFieldLabel: "Ephemeral Disk (GiB)",
    disabledFormFieldLabel: "Disabled",
    swapFormFieldLabel: "SWAP (MiB)",
    rxtxFactorFormFieldLabel: "RX/TX Factor",
    isPublicFormFieldLabel: "Is Public",
    accessTypeFormFieldLabel: "Access Type",
    formValuePublic: "Public",
    formValuePrivate: "Private",
    minRamFormFieldLabel: "Min RAM (MB)",
    minDiskFormFieldLabel: "Min Disk (GB)",
    disabledReasonFormFieldLabel: "Disabled Reason",
    hostFormFieldLabel: "Host",
    stateFormFieldLabel: "State",
    statusFormFieldLabel: "Status",
    updatedAtFormFieldLabel: "Updated At",
    forcedDownFormFieldLabel: "Forced Down",
    zoneFormFieldLabel: "Zone",
    progressFormFieldLabel: "Progress",
    flavorFormFieldLabel: "Flavor",
    accessIPv4FormFieldLabel: "Access IPv4",
    accessIPv6FormFieldLabel: "Access IPv6",
    addressesFormFieldLabel: "Addresses",
    imageFormFieldLabel: "Image",
    hostnameFormFieldLabel: "Hostname",
    availabilityZoneFormFieldLabel: "Availability Zone",
    sshKeyFormFieldLabel: "SSH Key",
    ipv4AddressFormFieldLabel: "IPv4 Address",
    ipv6AddressFormFieldLabel: "IPv6 Address",
    changesSinceFormFieldLabel: "Changes Since",
    filterName: "Name",
    filterEnabled: "Enabled",
    filterChoiceYes: "Yes",
    filterChoiceNo: "No",
    filterID: "ID",
    filterType: "Type",
    filterParentRegionID: "Parent Region ID",
    filterDomain: "Domain",
    filterParentProject: "Parent",
    filterIsDomain: "Is Domain",
    filterTags: "Tags",
    filterAllTags: "All Tags",
    allTagsFilterHelpText: "You may specify more than one tag, separated by comma. Records that contain all of the specified tags will be returned.",
    filterAnyTags: "Any Tags",
    anyTagsFilterHelpText: "You may specify more than one tag, separated by comma. Records that contain any of the specified tags will be returned.",
    filterNotTags: "Not All Tags",
    notTagsFilterHelpText: "You may specify more than one tag, separated by comma. Records that do not contain exactly all of the specified tags will be returned.",
    filterNotAnyTags: "Not Any Tags",
    notAnyTagsFilterHelpText: "You may specify more than one tag, separated by comma. Records that do not contain any one of the specified tags will be returned.",
    multifieldFilterHelpText: "You may combine one or more filters to build more complex filter query.",
    filterPasswordExpiresAt: "Password expires at",
    filterOperator: "Operator",
    filterChoiceLessThan: "Less than",
    filterChoiceGreaterThan: "Greater than",
    filterChoiceLessThanOrEquals: "Less than or equals",
    filterChoiceGreaterThanOrEquals: "Greater than or equals",
    filterChoiceEquals: "Equals",
    filterChoiceNotEquals: "Not equals",
    selectButtonTitleText: "Select",
    actionsDropdownLabelText: "Actions",
    applyButtonTitleText: "Apply",
    adminProjectSelectorTooltipText: "Select default Admin project",
    ramTableHeaderLabel: "RAM",
    diskTableHeaderLabel: "Disk",
    ephemeralTableHeaderLabel: "Ephemeral Disk",
    swapTableHeaderLabel: "SWAP",
    fingerprintFormFieldLabel: "Fingerprint",
    publicKeyFormFieldLabel: "Public Key",
    saveButtonText: "Save",
    editButtonText: "Edit",
    settings: "Settings",
    mode: "Mode",
    light: "Light",
    dark: "Dark",
    language: "Language",
    loadingHeader: "Loading",
    welcomeTitle: "Welcome to Universal OpenStack Admin UI",
    missingUsernameError: "Enter valid username!",
    missingPasswordError: "Enter valid password!",
    missingClientAccountIdError: "Enter valid client account ID!",
    clientAccountIdLabel: "Client Account ID",
    usernameLabel: "Username",
    passwordLabel: "Password",
    domainLabel: "OpenStack Domain Name",
    loginButtonTitle: "Login",
    unauthorizedDialogTitle: "Unauthorized!",
    unauthorizedDialogBody: "Authorization Failed! Try again.",
    auauthorizedDialogButtonTitle: "Close",
    forgotPassword: "Forgot Password",
    signUp: "Sign Up",
    contactUs: "Contact Us",
    usage: "Usage",
    currencySymbolUSD: "$",
    passwordRequirements: 'Password must be at least 8 characters long and contain uppercase letters, lowercase letters, numbers and special symbols',
    passwordNotMatch: 'Password does not match',
    signUpSuccessfull: 'You have successfully registered',
    resources: "Resources",
    resourceUsage: "Resource Usage",
    costSummary: "Cost Summary",
    instancesState: "Instances state",
    instances: "Instances",
    networks: "Networks",
    securityGroups: "Security Groups",
    volumes: "Volumes",
    publicIpAddresses: "Public IP Addresses",
    routers: "Routers",
    snapshots: "Snapshots",
    keyPairs: "Key Pairs",
    privateImages: "Private Images",
    monthToDateCost: "Month-to-date cost",
    comparedToLastMonthForSamePeriod: "compared to last month for same period",
    totalForcastedCostForCurrentMonth: "Total forcasted cost for current month",
    comparedToLastMonthTotalCost: "compared to last month total cost",
    totalCostForLastMonth: "Total cost for last month",
    running: "Running",
    stopped: "Stopped",
    vcpus: "vCPUs",
    ram: "RAM",
    storage: "Storage",
    ipAddresses: "IP Addresses",
    used: "Used",
    available: "Available",
    submenuDetails: "Details",
    submenuServers: "Servers",
    submenuServerGroups: "server groups",
    submenuFlavors: "Flavors",
    submenuKeypairs: "SSH Keys",
    submenuLimits: "limits",
    submenuInfrastructure: "infrastructure",
    flavorCreateSuccessResponseMessage: "New flavor was successfully created.",
    flavorUpdateSuccessResponseMessage: "Flavor was successfully updated.",
    flavorDeleteSuccessResponseMessage: "Flavor was successfully deleted.",
    addFlavorDialogTitle: "Add New Flavor",
    vCPUsHelpText: "The number of virtual CPUs that will be allocated to the server.",
    ramHelpText: "The amount of RAM a flavor has, in MiB.",
    diskHelpText: "The size of the root disk that will be created in GiB. If 0 the root disk will be set to exactly the size of the image used to deploy the instance. However, in this case the scheduler cannot select the compute host based on the virtual image size. Therefore, 0 should only be used for volume booted instances or for testing purposes.",
    ephemeralDiskHelpText: "The size of the ephemeral disk that will be created, in GiB. Ephemeral disks may be written over on server state changes. So should only be used as a scratch space for applications that are aware of its limitations.",
    swapHelpText: "The size of a dedicated swap disk that will be allocated, in MiB. If 0 (the default), no dedicated swap disk will be created.",
    rxtxFactorHelpText: "The receive / transmit factor (as a float) that will be set on ports if the network backend supports the QOS extension. Otherwise it will be ignored. It defaults to 1.0.",
    flavorAccessTypeHelpText: "Whether the flavor is public (available to all projects) or scoped to a set of projects. Default is True.",
    flavorDisableActionLabel: "Disable Flavor",
    flavorEnableActionLabel: "Enable Flavor",
    flavorDeleteActionLabel: "Delete Flavor",
    flavorMakeAccessPrivateActionLabel: "Make Private Access",
    flavorMakeAccessPublicActionLabel: "Make Public Access",
    privateFlavorAddProjectAccessActionLabel: "Add access to project",
    privateFlavorRemoveProjectAccessActionLabel: "Remove access from project",
    flavorAccessFormFieldLabel: "Flavor Access",
    allProjectsAccessTableLabelText: "All projects",
    showProjectsAccessTableLabelText: "Show",
    flavorAccessProjectsDialogTitle: "Flavor Access Projects",
    addFlavorAccessProjectsDialogTitle: "Add Flavor Access Project",
    removeFlavorAccessProjectsDialogTitle: "Remove Flavor Access Project",
    flavorAccessProjectsRemoveDialogMessage: "Select project below, which will be removed from allowed list to access selected flavor(s)",
    flavorAccessProjectsAddDialogMessage: "Select project below, which will be allowed to access selected flavor(s)",
    noAccessProjectsNoteText: "No projects allowed",
    keypairCreateSuccessResponseMessage: "New keypair was successfully created.",
    keypairDeleteSuccessResponseMessage: "Keypair was successfully deleted.",
    addKeypairDialogTitle: "Add SSH Key",
    addKeypairDialogDescriptionText: "Please, copy and paste your public key in form below.",
    keypairDeleteActionLabel: "Delete keypair",
    cpuCoresTitleText: "CPU Cores",
    ramTitleText: "RAM",
    serverInstancesTitleText: "Server Instances",
    serverGroupsTitleText: "Server Groups",
    totalAllowedKeypairsTitleText: "Total Allowed Keypairs",
    totalAllowedServerMetadataTitleText: "Total Allowed Server Metadata",
    totalAllowedServerGroupMembers: "Total Allowed Server Group Members",
    availableRAMGBLegendText: "Available RAM (GB)",
    totalUsedRAMGBLegendText: "Total Used RAM (GB)",
    availableCPUCoresLegendText: "Available CPU Cores",
    totalUsedCPUCoresLegendText: "Total Used CPU Cores",
    availableInstancesLegendText: "Available Instances",
    totalUsedInstancesLegendText: "Total Used Instances",
    availableServerGroupsLegendText: "Available Groups",
    totalUsedServerGroupsLegendText: "Total Used Groups",
    powerStateFormFieldLabel: "Power State",
    noStatePowerStatus:"No State",
    runningStatePowerStatus:"Running",
    pausedStatePowerStatus:"Paused",
    shutdownStatePowerStatus:"Shutdown",
    crashedStatePowerStatus:"Crashed",
    suspendedStatePowerStatus:"Suspended",
    ageFormFieldLabel: "Age",
    submenuVolumes: "Volumes",
    submenuInterfaces: "Interfaces",
    submenuSecurityGroups: "Security Groups",
    submenuMetadata: "Metadata",
    submenuUserData: "User Data",
    submenuFault: "Fault",
    adminPassChangeActionTitle: "Change admin password",
    addSecGroupActionTitle: "Add security group",
    removeSecGroupActionTitle: "Remove security group",
    createBackupActionTitle: "Create Backup",
    createImageActionTitle: "Create Image",
    triggerCrashDumpActionTitle: "Trigger crash dump",
    editServerActionTitle: "Edit Server",
    resizeServerActionTitle: "Resize Server",
    confirmResizeServerActionTitle: "Confirm Server Resize",
    lockServerActionTitle: "Lock Server",
    unlockServerActionTitle: "Unlock Server",
    rebuildServerActionTitle: "Rebuild Server",
    restoreSoftDeleteActionTitle: "Restore soft deleted",
    forceDeleteActionTitle: "Force Delete",
    shelveServerActionTitle: "Shelve Server",
    unshelveServerActionTitle: "Unshelve Server",
    shelfOffloadServerActionTitle: "Shelf offload (remove)",
    migrateServerActionTitle: "Migrate Server",
    liveMigrateServerActionTitle: "Live migrate server",
    attachVolumeActionTitle: "Attach Volume",
    detachVolumeActionTitle: "Detach Volume",
    attachIntefaceActionTitle: "Attach Interface",
    detachInerfaceActionTitle: "Detach Interface",
    addTagActionTitle: "Add Tag",
    deleteTagActionTitle: "Delete Tag",
    createUpdateMetadataActionTitle: "Create/update metadata",
    replaceMetadataActionTitle: "Replace metadata",
    removeMetadataActionTitle: "Remove metadata",
    serverActionCategoryTitle: "Security Actions",
    serverChangeActionCategoryTitle: "Modify Actions",
    serverBackupActionCategoryTitle: "Backup Actions",
    serverVolumesActionCategoryTitle: "Volume Actions",
    serverInterfacesActionCategoryTitle: "Interface Actions",
    serverTagsActionCategoryTitle: "Tag Actions",
    serverMetadataActionCategoryTitle: "Metadata Actions",
    failedActionErrorDialogTitle: "Action Failed!",
    failedActionErrorDialogMessage: "Your action could not be completed.",
    successActionDialogTitle: "Action Succeeded!",
    successActionDialogMessage: "Your action successfully completed.",
    addSecGroupActionDialogMessage: "Select security group to apply to server.",
    removeSecGroupActionDialogMessage: "Select security group to remove from server.",
    securityGroupFormFieldLabel: "Security Group",
    updateServerActionTitle: "Update Server Details",
    serverUpdateSuccessResponseTitle: "Success Update!",
    serverUpdateSuccessResponseMessage: "Server details successfully updated.",
    invalidIPAddressFormError: "Invalid IP Address",
    changeAdminPassActionTitle: "Change Admin Password",
    adminPassFormFieldLabel: "New Admin Password",
    serverInterfaceAttachActionTitle: "Attach New Interface",
    networkFormFieldLabel: "Network",
    fixedIPFormFieldLabel: "Fixed IP Address",
    networkInterfaceFormFieldLabel: "Network Interface",
    removeNetworkInterfaceActionTitle: "Remove Network Interface",
    removeNetworkInterfaceActionDialogMessage: "Select network interface below",
    volumeFormFieldLabel: "Volume",
    deleteOnTerminateFormFieldLabel: "Delete on server terminate",
    serverVolumeAttachActionTitle: "Attach New Volume",
    removeVolumeActionTitle: "Remove Volume",
    diskPartitionFormFieldLabel: "Disk Partition",
    manualPartitionConfigChoice: "Manual",
    autoPartitionConfigChoice: "Auto",
    serverResizeActionTitle: "Resize Server",
    serverMetadataAddActionTitle: "Add Extra Metadata",
    addMetadataFormFieldLabel: "Metadata key/value pair",
    serverStartActionWarningMessage1: "Server has wrong status.",
    serverStartActionWarningMessage2: "It must be",
    serverMetadataDeleteConfirmTitle: "Confirm Metadata Delete",
    serverMetadataDeleteConfirmText: "Are you sure, you want to delete selected metadata?",
    serverDeleteActionTitle: "Delete Server",
    serverDeleteConfirmTitle: "Confirm Server Delete",
    serverDeleteConfirmText: "Are you sure, you want to delete selected servers? This action is irreversible. Selected servers",
    serverConsoleTitle: "Console",
    policyFormFieldLabel: "Policy",
    rulesFormFieldLabel: "Policy Rules",
    membersFormFieldLabel: "Members",
    serverGroupPolicyHelpText: `
    <p>The policy represents the name of the policy.</p> 
    <p>The current valid policy names are: </p>
    <p><strong>- anti-affinity</strong> - servers in this group must be scheduled to different hosts. </p>
    <p><strong>- affinity</strong> - servers in this group must be scheduled to the same host. </p>
    <p><strong>- soft-anti-affinity</strong> - servers in this group should be scheduled to different hosts if possible, but if not possible then they should still be scheduled instead of resulting in a build failure. </p>
    <p><strong>- soft-affinity</strong> - servers in this group should be scheduled to the same host if possible, but if not possible then they should still be scheduled instead of resulting in a build failure.</p>
    `,
    maxServerPerHostFormFieldLabel: "Maximum servers per group",
    serverGroupDeleteActionTitle: "Delete Server Group",
    serverGroupDeleteConfirmTitle: "Confirm Server Group Delete",
    serverGroupDeleteConfirmText: "Are you sure, you want to delete selected server groups? This action is irreversible. Selected server groups",
    showStatsSubheaderButtonText: "Show Stats",
    hideStatsSubheaderButtonText: "Hide Stats",
    attachedVolumesTitleText: "Attached Volumes",
    volumeIDTitleText: "Volume ID",
    attachementDeviceTitleText: "Attached on",
    addNewServerInstanceTitle: "Add New Server Instance",
    addNewServerGroupTitle: "Add New Server Group",
    serversTabButtonText: "Servers",
    serverGroupsTabButtonText: "Server Groups",
    flavorDetailsDialogTitle: "Flavor",
    detailsErrorNoteDialogText: "Details",
    responseNoteDialogText: "Response",
    totalCPUUsageStatsTitle: "Total CPU Usage",
    totalRAMUsageStatsTitle: "Total RAM Usage",
    totalStorageUsageStatsTitle: "Total Storage Usage",
    serverTotalHoursUsageStatsTitle: "Servers Total Hours",
    selectedProjectsButtonText: "Selected Projects",
    allProjectsButtonText: "All Projects",
    selecteProjectsDialogTitleText: "Select Projects",
    selecteProjectsDialogMessageText: "Select one or more projects to recalculate usage statistics for selected projects, otherwise usage statistics for all projects is calculates",
    selectServerStatsDatesDialogTitleText: "Select Dates",
    selectServerStatsDatesDialogMessageText: "Select start and/or end time period for which usage statistics will be calculated, otherwise current month period will be selected.",
    startTimeTitleText: "Start Time",
    endTimeTitleText: "End Time",
    minutesText: "minutes",
    hoursText: "hours",
    daysText: "days",
    serverGroupsFormFieldLabel: "Server Groups",
    ageTableHeaderTitleText: "Age",
    flavorIDTitleText: "Flavor ID",
    flavorNameTitleText: "Flavor Name",
    computeHostFormFieldLabel: "Compute Host",
    hypervisorHostnameFormFieldLabel: "Hypervisor Hostname",
    sshKeyNameFormFieldLabel: "SSH Key Name",
    vmStateFormFieldLabel: "VM State",
    taskStateFormFieldLabel: "Task State",
    lunchedAtFormFieldLabel: "Lunched at",
    terminatedAtFormFieldLabel: "Terminated at",
    configDriveFormFieldLabel: "Config Drive",
    diskConfigFormFieldLabel: "Disk Config",
    instanceNameFormFieldLabel: "Instance Name",
    kernelIDFormFieldLabel: "Kernel ID",
    isLockedFormFieldLabel: "Is Locked",
    lockReasonFormFieldLabel: "Lock Reason",
    lunchIndexFormFieldLabel: "Lunch Index",
    ramdiskIDFormFieldLabel: "Ramdisk ID",
    reservationIDFormFieldLabel: "Reservation ID",
    rootDeviceNameFormFieldLabel: "Root Device Name",
    hostIDFormFieldLabel: "Host ID",
    detailsSubmenuTitleText: "Details",
    rulesFormFieldLablel: "Rules",
    directionFormFieldLabel: "Direction",
    etherTypeFormFieldLabel: "Ether Type",
    ipProtocolFormFieldLabel: "IP Protocol",
    portRangeFormFieldLabel: "Port Range",
    remoteIPPrefixFormFieldLabel: "Remote IP Prefix",
    remoteSecGroupFormFieldLabel: "Remote Security Group",
    anyKeywordText: "Any",
    appliedMetadataTitleText: "Applied Metadata",
    keyKeywordText: "Key",
    valueKeywordText: "Value",
    attachedInterfacesTitleText: "Attached Interfaces",
    ipAddressFormFieldLabel: "IP Address",
    subnetFormFieldLabel: "Subnet",
    macAddressFormFieldLabel: "MAC Address",
    portFormFieldLabel: "Port",
    faultCodeFormFieldLabel: "Fault Code",
    createdFormFieldLabel: "Created",
    faultMessageFormFieldLabel: "Fault Message",
    faultDetailsFormFieldLabel: "Fault Details",
    noUserDataNoteText: "No User Data",
    launchInstance: "Launch instance",
    submenuDomains: "Domains",
    submenuRegions: "Regions",
    submenuProjects: "Projects",
    submenuUsers: "Users",
    submenuGroups: "Groups",
    submenuRoles: "Roles",
    submenuEndpoints: "Endpoints",
    submenuCredentials: "Credentials",
    submenuUnifiedLimits: "Unified Limits",
    submenuServices: "Services Catalog",
    submenuCustomParams: "Custom Parameters",
    submenuAccessRules: "Access Rules",
    submenuApplicationCredentials: "App Credentials",
    deleteConfirmationDialogTitle: "Confirm Delete!",
    deleteDomainConfirmationDialogMessage: "Are you sure, you want to delete this domain?",
    confirmButtonText: "Confirm",
    domainCreateSuccessResponseMessage: "New domain was successfully created.",
    domainUpdateSuccessResponseMessage: "Domain was successfully updated.",
    domainDeleteSuccessResponseMessage: "Domain was successfully deleted.",
    addDomainDialogTitle: "Add New Domain",
    addDomainDialogDescriptionText: "A domain is a collection of users, groups, and projects. Each group and project is owned by exactly one domain.",
    updateDomainFormTitle: "Update Domain Details",
    regionCreateSuccessResponseMessage: "New region was successfully created.",
    regionUpdateSuccessResponseMessage: "Region was successfully updated.",
    regionDeleteSuccessResponseMessage: "Region was successfully deleted.",
    addRegionDialogTitle: "Add New Region",
    addRegionDialogDescriptionText: "A region is a general division of an OpenStack deployment. You can associate zero or more sub-regions with a region to create a tree- like structured hierarchy.",
    updateRegionFormTitle: "Update Region Details",
    parentRegionFormFieldLabel: "Parent Region",
    deleteRegionConfirmationDialogMessage: "Are you sure, you want to delete this region?",
    deleteProjectConfirmationDialogMessage: "Are you sure, you want to delete this project?",
    projectCreateSuccessResponseMessage: "New project was successfully created.",
    projectUpdateSuccessResponseMessage: "Project was successfully updated.",
    projectDeleteSuccessResponseMessage: "Project was successfully deleted.",
    addProjectDialogTitle: "Add New Project",
    addProjectDialogDescriptionText: "New project may act as a domain, if specified option 'Is Domain', otherwise it will be regular project. As a domain, the project provides a name space in which you can create users, groups, and other projects.",
    updateProjectFormTitle: "Update Project Details",
    noCustomParamsNoteText: "No Custom Parameters",
    noGroupsNoteText: "No Groups",
    noRolesNoteText: "No Roles",
    noProjectsNoteText: "No Projects",
    noAccessRulesNoteText: "No Access Rules",
    noApplicationCredentialsNoteText: "No Application Credentials",
    deleteUserConfirmationDialogMessage: "Are you sure, you want to delete this user?",
    userCreateSuccessResponseMessage: "New user was successfully created.",
    userUpdateSuccessResponseMessage: "User was successfully updated.",
    userDeleteSuccessResponseMessage: "User was successfully deleted.",
    addUserDialogTitle: "Add New User",
    updateUserFormTitle: "Update User Details",
    userDisableActionTitle: "Disable User",
    userEnableActionTitle: "Enable User",
    userAddParametersActionTitle: "Add Parameter(s)",
    userChangeParametersActionTitle: "Edit Parameter(s)",
    userChangePasswordActionTitle: "Change Password",
    userAddAppCredentialsActionTitle: "Add App Credential",
    userAddAppCredentialsDialogTitle: "Add New Application Credential",
    userPasswordChangeSuccessTitle: "Success!",
    userPasswordChangeSuccessText: "User's password has been changed!",
    userPasswordExpireAtActionTitle: "Password Expire",
    appCredentialAddSuccessMessage: "Application credential was successfully created. Please, copy and store credential secret key safely. It is not stored nor ever shown again. If the secret is lost, a new application credential must be created.",
    appCredentialRoleFormFieldHelpText: "Select optionally one or more roles. The list may only contain roles that the user has assigned on the project. If not provided, the roles assigned to the application credential will be the same as the roles in selected project.",
    appCredentialUnrestrectedFieldHelpText: "An optional flag to restrict whether the application credential may be used for the creation or destruction of other application credentials or trusts. Defaults to false.",
    accessRulePathFieldHelpText: "The 'path' attribute of application credential access rules uses a wildcard syntax to make it more flexible. For example, to create an application credential that is constricted to listing server IP addresses, you could use either of the following access rules: '/v2.1/servers/*/ips', '/v2.1/servers/{server_id}/ips'",
    appCredentialSecretFormFieldHelpText: "Provide secret that the application credential will be created with. If not provided, one will be generated.",
    appCredentialFormDescriptionText: "Application credentials provide a way to delegate a user’s authorization to an application without sharing the user’s password authentication. This is a useful security measure, especially for situations where the user’s identification is provided by an external source, such as LDAP or a single-sign-on service. Instead of storing user passwords in config files, a user creates an application credential for a specific project, with all or a subset of the role assignments they have on that project, and then stores the application credential identifier and secret in the config file.",
    noUsersNoteText: "No Users",
    deleteGroupConfirmationDialogMessage: "Are you sure, you want to delete this group?",
    groupCreateSuccessResponseMessage: "New group was successfully created.",
    groupUpdateSuccessResponseMessage: "Group was successfully updated.",
    groupDeleteSuccessResponseMessage: "Group was successfully deleted.",
    addGroupDialogTitle: "Add Group",
    updateGroupFormTitle: "Update Group Details",
    groupAddUserActionTitle: "Add user to group",
    groupRemoveUserActionTitle: "Remove user from group",
    addUserToGroupDialogTitle: "Add User to Group",
    removeUserToGroupDialogTitle: "Remove User from Group",
    addRemoveUserToGroupDialogText: "Select user from list below",
    noRoleAssignmentsNoteText: "No Role Assignments",
    deleteRoleConfirmationDialogMessage: "Are you sure, you want to delete this role?",
    roleCreateSuccessResponseMessage: "New role was successfully created.",
    roleUpdateSuccessResponseMessage: "Role was successfully updated.",
    roleDeleteSuccessResponseMessage: "Role was successfully deleted.",
    addRoleDialogTitle: "Add New Role",
    addRoleDialogDescriptionText: "If you don't select domain, the role will be system wide.",
    updateRoleFormTitle: "Update Role Details",
    roleAssignToUserActionTitle: "Assign to user",
    roleAssignToGroupActionTitle: "Assign to group",
    roleUnassignFromUserActionTitle: "Unassign from user",
    roleUnassignFromGroupActionTitle: "Unassign from group",
    assignRoleToGroupDialogTitle: "Assign Role to Group",
    unassignRoleFromGroupDialogTitle: "Unassign Role from Group",
    assignRoleToUserDialogTitle: "Assign Role to User",
    unassignRoleFromUserDialogTitle: "Unassign Role from User",
    assignUnassignRoleToUserDialogText: "Select scope and user from list below",
    assignUnassignRoleToGroupDialogText: "Select scope and group from list below",
    systemScopeFormFieldLabel: "System",
    domainScopeFormFieldLabel: "Domain",
    projectScopeFormFieldLabel: "Project",
    scopeFormFieldLabel: "Scope",
    roleAssignmentsForTitle: "Role Assignments for",
    roleAssignmentsTitle: "Role Assignments",
    showButtonText: "Show",
    roleSystemScopeTitle: "System Scope",
    roleDomainScopeTitle: "Domain Scope",
    roleProjectScopeTitle: "Project Scope",
    deleteRoleAssignmentDialogTitle: "Delete Role Assignment",
    deleteRoleAssignmentDialogMessage: "Are you sure, you want to delete this role assignment?",
    deleteButtonText: "Delete",
    serviceCreateSuccessResponseMessage: "New service was successfully created.",
    serviceUpdateSuccessResponseMessage: "Service was successfully updated.",
    serviceDeleteSuccessResponseMessage: "Service was successfully deleted.",
    endpointCreateSuccessResponseMessage: "New endpoint was successfully created.",
    endpointUpdateSuccessResponseMessage: "Endpoint was successfully updated.",
    endpointDeleteSuccessResponseMessage: "Endpoint was successfully deleted.",
    noServiceEndpointsNoteText: "No Service Endpoints",
    addServiceDialogTitle: "Add New Service",
    updateServiceFormTitle: "Update Service Details",
    deleteServiceConfirmationDialogMessage: "Are you sure, you want to delete this service?",
    deleteEndpointConfirmationDialogMessage: "Are you sure, you want to delete this endpoint?",
    serviceAddEndpointActionTitle: "Add New Endpoint",
    publicInterfaceFormFieldLabel: "Public",
    internalInterfaceFormFieldLabel: "Internal",
    adminInterfaceFormFieldLabel: "Admin",
    serviceEditEndpointTitle: "Edit Endpoint Details",
    submenuImages: "Images",
    submenuMetadatas: "Metadata",
    visibilityFormFieldLabel: "Visibility",
    protectedFormFieldLabel: "Protected",
    diskFormatFormFieldLabel: "Disk Format",
    sizeFormFieldLabel: "Size",
    queuedImageStatusText: "Queued",
    savingImageStatusText: "Saving",
    activeImageStatusText: "Active",
    killedImageStatusText: "Killed",
    deletedImageStatusText: "Deleted",
    pendingDeleteImageStatusText: "Pending delete",
    deactivatedImageStatusText: "Deactivated",
    uploadingImageStatusText: "Uploading",
    importingImageStatusText: "Importing",
    publicVisibilityImageText: "Public",
    communityVisibilityImageText: "Community",
    sharedVisibilityImageText: "Shared",
    privateVisibilityImageText: "Private",
    hiddenImageStatusText: "Hidden",
    memberStatusImageText: "Member Status",
    acceptedMeberStatusImageText: "Accepted",
    pendingMeberStatusImageText: "Pending",
    rejectedVisibilityImageText: "Rejected",
    imageCreateSuccessResponseMessage: "Image successfully created!",
    imageUpdateSuccessResponseMessage: "Image successfully updates!",
    imageDeleteSuccessResponseMessage: "Image successfully deleted!",
    addImageDialogTitle: "Add New Image",
    addImageDialogDescriptionText: "You can create new image and upload it's file.",
    readyFormFieldLabel: "Ready",
    minSizeFormFieldLabel: "Size (Min)",
    maxSizeFormFieldLabel: "Size (Max)",
    amiContainerFormatTitle: "AMI - Amazon Machine Image",
    ariContainerFormatTitle: "ARI - Amazon Ramdisk Image",
    akiContainerFormatTitle: "AKI - Amazon Kernel Image",
    bareContainerFormatTitle: "Bare image container",
    ovfContainerFormatTitle: "OVF container format",
    ovaContainerFormatTitle: "OVA tar archive file",
    dockerContainerFormatTitle: "Docker tar archive",
    vhdContainerFormatTitle: "VHD - Virtual Hard Disk",
    vhdxContainerFormatTitle: "VHDX - Virtual Hard Disk Enhanced",
    vmdkContainerFormatTitle: "VMDK - Virtual Machine Disk",
    rawContainerFormatTitle: "RAW - Unstructured Disk Image",
    qcow2ContainerFormatTitle: "QCOW2 - QEMU Emulator",
    vdiContainerFormatTitle: "VDI - Virtual Disk Image",
    ploopContainerFormatTitle: "PLOOP - Virtuozzo/Parallels Loopback Disk",
    isoContainerFormatTitle: "ISO - Optical Disk Image",
    containerFormatFormFieldLabel: "Container Format",
    chooseFileButtonText: "Choose File",
    chooseImageFileDescriptionText: "Choose image file to upload.",
    checksumFormFieldLabel: "Checksum",
    hashAlgorithmFormFieldLabel: "Hash Algorithm",
    hashValueFormFieldLabel: "Hash Value",
    imageUpdateActionTitle: "Update Image",
    imageDeleteActionTitle: "Delete Image",
    uploadImageFileButtonText: "Upload Image File",
    hiddenFormFieldLabel: "Hidden",
    architectureFormFieldLabel: "Architecture",
    imageDeleteConfirmTitle: "Delete Image",
    imageDeleteConfirmText: "Are you sure, you want to delete selected images? This action is irreversible. Selected images",
    imageMetadataUpdateActionTitle: "Update Image Metadata",
    minLabel: "Min",
    maxLabel: "Max",
    bytesLabel: "Bytes",
    kiloBytesLabel: "Kilobytes",
    megaBytesLabel: "Megabytes",
    addImageDialogDescriptionExtendedText: "You can create new image and upload it's file. Note, that created image will be private and visible only for instances in current project. To share your image with other projects, please contact your administrator.",
    submenuVolumeTypes: "Volume Types",
    submenuGroupSnapshots: "Group Snapshots",
    submenuGroupTypes: "Group Types",
    submenuSnapshots: "Snapshots",
    submenuBackups: "Backups",
    submenuClusters: "Clusters",
    submenuTransfers: "Transfers",
    qosSpecsFormFieldLabel: "Associated QoS Specs",
    addVolumeTypeDialogTitle: "Add Volume Type",
    extraSpecsFormFieldLabel: "Extra Specs",
    addQoSSpecsDialogTitle: "Add QoS Specs",
    volumeTypesTabButtonText: "Volume Types",
    qosSpecsTabButtonText: "QoS Specs",
    specsFormFieldLabel: "Specs",
    consumerFormFieldLabel: "Consumer",
    qosSpecsConsumerFormFieldHelpText: `<p>When you create a QoS specification you must choose the required consumer. The consumer determines where you want to apply the QoS limits and determines which QoS property keys are available to define the QoS limits. The Block Storage service (cinder) supports the following consumers of QoS specifications:</p>
    <lu><li><strong>front-end</strong>: The Compute service (nova) applies the QoS limits when the volume is attached to an instance. The Compute service supports all the QoS property keys provided by the Block Storage service.</li>
    <li><strong>back-end</strong>: The back-end driver of the associated volume type applies the QoS limits. Each back-end driver supports their own set of QoS property keys. For more information on which QoS property keys the driver supports, see the back-end driver documentation.</li>
    <p>You would use the back-end consumer in cases where the front-end consumer is not supported. For instance, when attaching volumes to bare metal nodes through the Bare Metal Provisioning service (ironic).</p>
    <li><strong>both</strong>: Both consumers apply the QoS limits, where possible. This consumer type therefore supports the following QoS property keys:</li>
    <p>When a volume is attached to an instance, then you can use every QoS property key that both the Compute service and the back-end driver supports.</p>
    <p>When the volume is not attached to an instance, then you can only use the QoS property keys that the back-end driver supports.</p></lu>`,
    qosSpecsPropertiesHelpText: `<p>Refer to Cinder documentation for the supported front-end and back-end keys.</p>
    <p>Front-End Example:</p>
    <lu><li>Key: total_iops_sec and Value: 5000</li>
    <li>Key: total_bytes_sec_max and Value: 512000</li>
    <li>Key: size_iops_sec and Value: 16</li></lu>
    <p>Back-End Example:<p>
    <lu><li>Key: minIOPS and Value: 20 (number value less than maxIOPS)</li>
    <li>Key: maxIOPS and Value: 5000 (number value bigger than minIOPS)</li>
    <li>Key: burstIOPS and Value: 5000 (number value bigger than minIOPS)</li></lu>`,
    encryptionFormFieldLabel: "Encryption",
    encryptionDeleteActionTitle: "Delete encryption",
    encryptionUpdateActionTitle: "Update encryption",
    encryptionCreateActionTitle: "Create encryption",
    updateExtraSpecsActionTitle: "Update extra specs",
    updateVTAccessActionTitle: "Update access",
    updateVolumeTypeFormTitle: "Update Volume Type",
    noQoSSpecsAssociationNoteText: "No QoS Specs Associated",
    noExtraSpecsNoteText: "No Extra Specs",
    controlLocationFormFieldLabel: "Control Location",
    cipherFormFieldLabel: "Cipher",
    keySizeFormFieldLabel: "Key Size",
    submenuExtraSpecs: "Extra Specs",
    submenuQoSSpecs: "QoS Specs",
    submenuEncryption: "Encryption",
    noEncryptionNoteText: "No Encryption",
    providerFormFieldLabel: "Provider",
    submenuAccess: "Access",
    noProjectAccessNoteText: "No Project Access",
    luksFormFieldChoiceText: "luks - relies on Linux Unified Key Setup",
    plainFormFieldChoiceText: "plain - relies on dm-crypt",
    encryptionCreateDialogTitle: "Create Encryption",
    encryptionCreateDialogText: "Creating encryption for a volume type causes all volumes with that volume type to be encrypted. Encryption information cannot be added to a volume type if volumes are currently in use with that volume type.",
    volumeTypeDeleteActionTitle: "Delete volume type",
    volumeTypeDeleteConfirmTitle: "Delete Volume Type",
    volumeTypeDeleteConfirmText: "Are you sure, you want to delete selected volume types? This action can not be reverted. Selected volume types",
    encryptionUpdateDialogTitle: "Update Encryption",
    qosSpecsDeleteConfirmTitle: "Delete QoS Specs",
    qosSpecsDeleteConfirmText: "Are you sure, you want to delete selected qos specs? This action can not be reverted. Selected qos specs",
    qosSpecsDeleteActionTitle: "Delete qos specs",
    qosSpecsDisassociateAllActionTitle: "Disassociate all",
    qosSpecsDisassociateAllConfirmTitle: "Disassociate All Volume Types",
    qosSpecsDisassociateAllConfirmText: "Are you sure, you want to disassociate selected qos specs from all volume types? Selected qos specs",
    noQoSSpecsNoteText: "No QoS Specs",
    noQoSSpecAssociationsNoteText: "No Associations",
    deleteVTConfirmationDialogMessage: "Are you sure, you want to delete this volume type?",
    deleteQoSSpecConfirmationDialogMessage: "Are you sure, you want to delete this qos specs?",
    submenuQoSSpecsAssociations: "Associations",
    qosSpecAssociateToVTActionTitle: "Associate to volume type",
    qosSpecDisassociateFromVTActionTitle: "Disassociate from volume type",
    associateToVolumeTypeActionTitle: "Associate to Volume Type",
    associateToVolumeTypeActionDialogMessage: "Select volume type below to associate this qos specs to",
    volumeTypeFormFieldLabel: "Volume Type",
    disassociateVolumeTypeActionTitle: "Disassociate Volume Type",
    disassociateVolumeTypeActionDialogMessage: "Select volume type below, to disassociate this qos specs from",
    qosSpecsUpdateActionTitle: "Update qos specs",
    errorUpdateQoSSpecsTitle: "QoS Spec Update Error",
    errorUpdateQoSSpecsMessage: "Failed to update qos specs data",
    defaultVolumeTypesTabButtonText: "Default Volume Types",
    projectIDFormFieldLabel: "Project ID",
    projectNameFormFieldLabel: "Project Name",
    setDefaultVolumeTypeActionTitle: 'Set default volume type',
    unsetDefaultVolumeTypeActionTitle: "Unset default volume type",
    volumeTransfersTabButtonText: "Volume Transfers",
    volumesTabButtonText: "Volumes",
    addVolumeDialogTitle: "Add New Volume",
    bootableFormFieldLabel: "Bootable",
    encryptedFormFieldLabel: "Encrypted",
    attachedToFormFieldLabel: "Attached to",
    volumeStatusChoiceAvailable: "Available",
    volumeStatusChoiceInUse: "In Use",
    volumeStatusChoiceError: "Error",
    volumeStatusChoiceCreating: "Creating",
    volumeStatusChoiceExtending: "Extending",
    volumeDeleteActionTitle: "Delete volume",
    attachToServerActionTitle: "Attach to server",
    detachFromServerActionTitle: "Detach from server",
    updateBootableStatusActionTitle: "Update bootable status",
    changeVolumeTypeActionTitle: "Change type",
    bootableStatusFormFieldLabel: "Bootable status",
    enableFormFieldLabel: "Enable",
    disableFormFieldLabel: "Disable",
    updateVolumeTypeActionTitle: "Update Volume Type",
    migrationPolicyFormFieldLabel: "Migration policy",
    onDemandFormChoiceLabel: "On Demand",
    neverFormChoiceLabel: "Never",
    forceVolumeDeleteFormFieldLabel: "Force volume delete",
    withSnapshotsFormFieldLabel: "Delete snapshots",
    volumeDeleteConfirmText: "Are you sure, you want to delete selected volumes? This action can not be reverted and all data on volume will be deleted. Selected volumes",
    volumeDeleteConfirmTitle: "Confirm volume delete",
    submenuAttachments: 'Attachments',
    noSnapshotsNoteText: "No Snapshots",
    noBackupsNoteText: "No Backups",
    volumeSourceFormFieldLabel: "Volume Source",
    clusterFormFieldLabel: "Cluster",
    multiattachFormFieldLabel: "Multiattach",
    serverAttachmentTitleText: "Server Attachment(s)",
    volumeMetadataDeleteConfirmText: "Are you sure, you want to delete this metadata?",
    volumeMetadataDeleteConfirmTitle: "Delete Volume Metadata",
    volumeSnapshotsTitleText: "Volume Snapshots",
    volumeBackupsTitleText: "Volume Backups",
    isIncrementalFormFieldLabel: "Incremental",
    volumeUpdateActionTitle: "Update volume",
    volumeExtendActionTitle: "Extend volume",
    volumeAttachToServerActionTitle: "Attach to server",
    volumeDetachFromServerActionTitle: "Detach from server",
    volumeStatusUpdateActionTitle: "Update volume status",
    createSnapshotActionTitle: "Create snapshot",
    createTransferActionTitle: "Create transfer",
    uploadToImageActionTitle: "Upload to image",
    updateMetadataActionTitle: "Update metadata",
    volumeMigrateActionTitle: "Migrate volume",
    updateVolumeActionTitle: "Update Volume Details",
    availableStatusFormFieldLabel: "Available",
    inuseStatusFormFieldLabel: "In-use",
    errorStatusFormFieldLabel: "Error",
    creatingStatusFormFieldLabel: "Creating",
    attachingStatusFormFieldLabel: "Attaching",
    detachingStatusFormFieldLabel: "Detaching",
    deletingStatusFormFieldLabel: "Deleting",
    errorDeletingStatusFormFieldLabel: "Error deleting",
    maintenanceStatusFormFieldLabel: "Maintenance",
    reservedStatusFormFieldLabel: "Reserved",
    updateVolumeStatusActionTitle: "Update Volume Status",
    extendVolumeSizeActionTitle: "Extend Volume Size",
    invalidVolumeSizeFormFieldError: "Volume size must be greater than current size",
    attachVolumeToServerActionTitle: "Attach Volume to Server",
    serverFormFieldLabel: "Server",
    uploadVolumeToImageActionTitle: "Upload Volume to Image",
    volumeMetadataUpdateActionTitle: "Update Volume Metadata",
    createVolumeTransferActionTitle: "Create Volume Transfer",
    transferNameFormFieldLabel: "Transfer Name",
    sourceProjectFormFieldLabel: "Source",
    destinationProjectFormFieldLabel: "Destination",
    acceptedFormFieldLabel: "Accepted",
    volumeTransferDeleteActionTitle: "Delete transfer",
    volumeTransferDeleteConfirmText: "Are you sure, you want to delete selected volume transfers?",
    volumeTransferDeleteConfirmTitle: "Delete Volume Transfer",
    volumeTransferAuthKeyDetails: "Volume transfer must be accepted with the folowing auth key. Please, copy and save this key safely, because it is shown only once. Auth Key: ",
    volumeTransferSuccessDialogTitle: "Success Transfer",
    volumeTransferSuccessDialogMessage: "Volume transfer is created and is awaiting to be accepted.",
    volumeTransferAcceptActionTitle: "Accept transfer",
    acceptVolumeTransferActionTitle: "Accept Volume Transfer",
    authKeyFormFieldLabel: "Auth Key",
    snapshotStatusChoiceDeleting: "Deleting",
    groupSnapshotFormFieldLabel: "Group Snapshot",
    snapshotFormFieldLabel: "Snapshot",
    increnemtalFormFieldLabel: "Incremental",
    volumeGroupTypeFormFieldLabel: "Group Type",
    volumeGroupFormFieldLabel: "Volume Group",
    snapshotDeleteConfirmTitle: "Confirm Snapshot Delete",
    snapshotDeleteConfirmText: "Are you sure, you want to delete selected snapshots? This action can not be reverted. Selected snapshots",
    sourceFormFieldLabel: "Source",
    blankVolSourceChoiceTitle: "No source, empty volume",
    volumeSourceChoiceTitle: "Volume",
    imageVolSourceChoiceTitle: "Image",
    snapshotVolSourceChoiceTitle: "Snapshot",
    backupVolSourceChoiceTitle: "Backup",
    backupFormFieldLabel: "Backup",
    nonAllowedActionExecutionWarning: "You are trying to execute action, which requires Project Scoped Authorization to project",
    notRequiredRole: "Currently, you are not assigned required role",
    getAdminRoleToGivenProject: "Click to obtain required role assignment",
    addSnapshotDialogTitle: "Add Snapshot",
    snapshotDeleteActionTitle: "Delete snapshot",
    snapshotStatusUpdateActionTitle: "Update status",
    snapshotStatusUpdateTitle: "Update Snapshot Status",
    snapshotStatusFormFieldLabel: "Snapshot status",
    updateSnapshotFormTitle: "Update Snapshot",
    metadataFormFieldLabel: "Add Metadata",
    noMetadataNoteText: "No Metadata",
    incrementalFormFieldLabel: "Incremental",
    addBackupDialogTitle: "Add Backup",
    backupDeleteConfirmTitle: "Delete Backup",
    backupDeleteConfirmText: "Are you sure, you want to delete selected backups? This action can not be reverted. Selected backups",
    backupStatusUpdateTitle: "Update Backup Status",
    backupStatusFormFieldLabel: "Backup Status",
    updateBackupFormTitle: "Update Backup",
    backupDeleteActionTitle: "Delete backup",
    backupStatusUpdateActionTitle: "Update status",
    encryptionKeyFormFieldLabel: "Encryption Key",
    hasDependendBackupFormFieldLabel: "Has Dependend Backups",
    objectCountFormFieldLabel: "Object Count",
    containerFormFieldLabel: "Container",
    dataTimestampFormFieldLabel: "Data Timestamp",
    failReasonFormFieldLabel: "Fail Reason",
    backupUpdateActionTitle: "Edit Backup",
    updateBackupActionTitle: "Edit Backup",
    restoreBackupActionTitle: "Restore Backup",
    createVolumeLabel: "Create Volume",
    attachedToServerLabel: "Attached to Server",
    notAttachedLabel: "Not Attached",
    volumeTypesListFormFieldLabel: "Volume Types List",
    groupDeleteActionTitle: "Delete group",
    groupDeleteConfirmTitle: "Delete Volume Group",
    groupDeleteConfirmText: "Are you sure, you want to delete selected groups? This action can not be reverted. Selected groups",
    deleteVolumesFormFieldLabel: "Delete volumes",
    updateVolumesListActionTitle: "Update volumes list",
    createGroupSnapshotActionTitle: "Create group snapshot",
    updateVolumesListDialogTitle: "Update Volumes in Group",
    noVolumesAddedToGroupNoteText: "Empty volume list",
    groupTypesTabButtonText: "Group Types",
    addGroupTypeDialogTitle: "Add New Group Type",
    groupTypeDeleteActionTitle: "Delete group type",
    groupTypeDeleteConfirmTitle: "Delete Group Type",
    groupTypeDeleteConfirmText: "Are you sure, you want to delete selected group types? This action can not be reverted. Selected group types",
    errorDeleteGroupTypeTitle: "Group Type Delete Failed",
    errorDeleteGroupTypeMessage: "Error occurred during group type delete.",
    errorDeleteRecordTitle: "Delete Failed",
    errorDeleteRecordMessage: "An error occurred during deleting one of selected records.",
    noVolumesNoteText: "No Volumes",
    volumesLimitTitleText: "Volumes",
    availableVolumesLegendText: "Available Volumes",
    totalUsedVolumesLegendText: "Total Used Volumes",
    storageLimitTitleText: "Storage (GB)",
    availableStorageGBLegendText: "Available Storage (GB)",
    totalUsedStorageGBLegendText: "Total Used Storage (GB)",
    snapshotsLimitTitleText: "Snapshots",
    availableSnapshotsLegendText: "Available Snapshots",
    totalUsedSnapshotsLegendText: "Total Used Snapshots",
    backupsLimitTitleText: "Backups",
    availableBackupsLegendText: "Available Backups",
    totalUsedBackupsLegendText: "Total Used Backups",
    backupStorageLimitTitleText: "Backup Storage (GB)",
    backupStorageLabel: "Backup Storage",
    availableBackupsGBLegendText: "Available Backups (GB)",
    totalUsedBackupsGBLegendText: "Total Used Backups (GB)",
    volumeGroupsLimitTitleText: "Volume Groups",
    availableVolumeGroupsLegendText: "Available Groups",
    totalUsedVolumeGroupsLegendText: "Total Used Groups",
    customFieldsTitleText: "Custom Fields",
    duplicateFieldsAreNotAllowedErrorText: "Duplicate fields are not allowed",
    catalogHeaderTitle: "Catalog",
    existingFieldsHeaderTitle: "Existing Fields",
    instructionBoxNoteText: "Click each item to get its description here.",
    noDescriptionText: "No Description",
    warningDialogTitle: "Warning",
    addToListButtonTitle: "Add To List",
    noProjectsSelectedNoteText: "No Project Selected",
    submenuL2Networks: "L2 Networking",
    submenuL3Networks: "L3 Networking",
    submenuSecurity: "Security",
    submenuFWaaS: "Firewall",
    submenuVPNaaS: "VPN",
    submenuQoS: "QoS",
    submenuNetworkFlavors: "Network Flavors",
    submenuNetworkLogging: "Logging",
    submenuNetworkLimits: "Limits",
    submenuNetworkTopology: "Topology",
    adminStatusFormFieldLabel: "Admin State",
    upStateFormFieldLabel: "Up",
    downStateFormFieldLabel: "Down",
    addressScopesFormFieldLabel: "Address Scopes",
    networkTypeFormFieldLabel: "Network Type",
    segmentFormFieldLabel: "Segment (VLAN,GRE)",
    externalFormFieldLabel: "External",
    sharedFormFieldLabel: "Shared",
    isDefaultNetworkFormFieldLabel: "Is Default",
    mtuFormFieldLabel: "MTU",
    portSecurityEnabledFormFieldLabel: "Port Security Enabled",
    revisionNumberFormFieldLabel: "Revision Number",
    subportsNumberFormFieldLabel: "Subports",
    qosPolicyFormFieldLabel: "QoS Policy",
    addNetworkDialogTitle: "Add New Network",
    networksTabButtonText: "Networks",
    segmentsTabButtonText: "Segments",
    portsTabButtonText: "Ports",
    trunksTabButtonText: "Trunks",
    flatNetworkChoiceTitle: "Flat Network",
    vlanChoiceTitle: "VLAN",
    vxlanChoiceTitle: "VXLAN",
    greChoiceTitle: "GRE",
    localChoiceTitle: "Local",
    geneveChoiceTitle: "Geneve",
    dnsDomainFormFieldLabel: "DNS Domain",
    defaultNetworkFormFieldLabel: "Default Network",
    adminStateUpFormFieldLabel: "Admin Up",
    externalNetworkFormFieldLabel: "External Network",
    sharedNetworkFormFieldLabel: "Shared Network",
    vlanTransparentFormFieldLabel: "Vlan Transparent",
    segmentationIDFormFieldLabel: "Segmentation ID",
    adminStateFormFieldLabel: "Admin Up",
    networkDeleteConfirmTitle: "Delete Network",
    networkDeleteConfirmText: "Are you sure you want to delete selected networks? This action can not be reverted. Selected networks",
    networkDeleteActionTitle: "Delete network",
    supportSegmentationCheckbox: "Support Segmentation",
    segmentFormFieldTitle: "Segment",
    networkSegmentsTitleTexts: "Network Segments",
    physicalNetworkFormFieldLabel: "Physical Network",
    networkUpdateAdminStateActionTitle: "Update admin state",
    networkUpdateTitle: "Update Network",
    networkUpdatePortSecurityActionTitle: "Enable/disable port security",
    networkUpdateSharedStatusActionTitle: "Update shared status",
    networkUpdateExternalStatusActionTitle: "Update external status",
    networkUpdateDefaultStatusActionTitle: "Update default status",
    networkUpdateMTUActionTitle: "Update MTU",
    noSegmentsFormFieldLabel: "No Segment",
    networkUpdateActionTitle: "Update network",
    noSubnetsNoteText: "No Subnets",
    noSegmentsNoteText: "No Segments",
    l2AdjacencyFormFieldLabel: "L2 Adjacency",
    submenuSubnets: "Subnets",
    submenuNetworkSegments: "Segments",
    updateNetworkActionTitle: "Update Network Details",
    networkSubnetsTitleTexts: "Network Subnets",
    ipVersionFormFieldLabel: "IP Version",
    cidrFormFieldLabel: "CIDR",
    gatewayFormFieldLabel: "Gateway IP",
    dhcpEnabledFormFieldLabel: "DHCP Enabled",
    propagateUplinkStatusFormFieldLabel: "Propagate Uplink Status",
    macLearningEnabledFormFieldLabel: "MAC Learning Enabled",
    ipAddressOrSubnetFormFieldLabel: "Specify IP address or subnet",
    addPortDialogTitle: "Add Port",
    dnsNameFormFieldLabel: "DNS Name",
    securityGroupsFormFieldLabel: "Security Groups",
    invalidIPAddressErrorText: "Invalid IP Address",
    normalVnicTypeChoiceText: "Notmal",
    directVnicTypeChoiceText: "Direct",
    directPhysicalVnicTypeChoiceText: "Direct Physical",
    macVTapVnicTypeChoiceText: "MacVTap",
    bareMetalVnicTypeChoiceText: "Bare Metal",
    virtioForwarderVnicTypeChoiceText: "Virtio Forwarder",
    smartNicVnicTypeChoiceText: "Smart NIC",
    remoteManagedVnicTypeChoiceText: "Remote Managed",
    vnicTypeFormFieldLabel: "VNIC Type",
    portUpdateAdminStateActionTitle: "Update admin state",
    portUpdatePortSecurityActionTitle: "Update port security",
    portDeleteActionTitle: "Delete port",
    portUpdateSecurityGroupsActionTitle: "Update security groups",
    portDeleteConfirmTitle: "Confirm Port Delete",
    portDeleteConfirmText: "Are you sure, you want to delete selected ports? This action can not be reverted. Selected ports",
    portUpdateTitle: "Update Port Details",
    updatePortActionTitle: "Update port",
    deviceOwnerFormFieldLabel: "Device Owner",
    submenuSecGroups: "Security Groups",
    submenuAllowedAddresses: "Allowed Addresses",
    portUpdateActionTitle: "Update Port",
    noSecGroupsNoteText: "No Security Groups",
    noAddressPairsNoteText: "No Address Pairs",
    updateAllowedAddressesActionTitle: "Update Allowed Addresses",
    allowedAddressesFormFieldLabel: "Add Allowed Address Pairs",
    allowedAddressPairsTableTitle: "Allowed Address Pairs",
    updateIPAddressActionTitle: "Update IP Address",
    gatewayIpFormFieldLabel: "Gateway IP",
    enableDhcpFormFieldLabel: "Enable DHCP",
    dnsNameserversFormFieldLabel: "DNS Nameservers",
    addSubnetDialogTitle: "Add Subnet",
    subnetsTabButtonText: "Subnets",
    floatingIPsTabButtonText: "Floating IPs",
    routersTabButtonText: "Routers",
    virtualIPsTabButtonText: "Virtual IPs",
    ipv4FormFieldLabel: "IPv4",
    ipv6FormFieldLabel: "IPv6",
    ipv6AddressModeFormFieldLabel: "IPv6 Address Mode",
    notSelectedFormFieldLabel: "None",
    slaacFormFieldLabel: "SLAAC",
    dhcpv6StatefullFormFieldLabel: "DHCPv6 Statefule",
    dhcpv6StatelessFormFieldLabel: "DHCPv6 Stateless",
    ipv6RouterModeFormFieldLabel: "IPv6 Router Mode",
    destinationFormFieldLabel: "Destination CIDR",
    nextHopFormFieldLabel: "Next Hop",
    startFormFieldLabel: "Start Address",
    endFormFieldLabel: "End Address",
    allocationPoolFormFieldLabel: "Allocation Pool",
    addAllocationPoolFormFieldLabel: "Add Allocation Pool",
    dnsNameserverFormFieldLabel: "DNS Nameserver",
    addDnsNameserverFormFieldLabel: "Add DNS Nameserver",
    hostRouteFormFieldLabel: "Host Route",
    addHostRouteFormFieldLabel: "Add Host Route",
    disableGatewayFormFieldLabel: "Disable Gateway",
    createNetworkLabel: "Create Network",
    hostRoutesFormFieldLabel: "Host Routes",
    subnetDeleteActionTitle: "Delete subnet",
    subnetDeleteConfirmTitle: "Confirm Subnet Delete",
    subnetDeleteConfirmText: "Are you sure, you want to delete selected subnets? This action can not be reverted. Selected subnets",
    fullSubnetFormFieldLabel: "Full Subnet",
    allocationPoolsFormFieldLabel: "Allocation Pools",
    subnetUpdateActionTitle: "Update subnet",
    subnetUpdateAllocationPoolsActionTitle: "Update allocation pools",
    subnetUpdateDNSNameserversActionTitle: "Update DNS nameservers",
    subnetUpdateHostRoutesActionTitle: "Update host routes",
    subnetDisableDhcpActionTitle: "Disable DHCP",
    subnetEnableDhcpActionTitle: "Enable DHCP",
    subnetUpdateGatewayIPActionTitle: "Update gateway IP",
    updateSubnetActionTitle: "Update Subnet Details",
    updateAllocationPoolsActionTitle: "Update Allocation Pools",
    disableGatewayIPFormFieldLabel: "Disable Gateway IP",
    invalidCIDRErrorText: "Invalid CIDR provided",
    invalidPrefixLengthErrorText: "Invalid Prefix Length for selecte IP Version",
    selectProjectText: "Select project",
    mappedLocalIPFormFieldLabel: "Mapped Local IP Address",
    portForwardingFormFieldLabel: "Port Forwarding",
    poolFormFieldLabel: "Pool",
    specificAddressFormFieldLabel: "Specific Address",
    activeStatusText: "Active",
    downStatusText: "Down",
    errorStatusText: "Error",
    floatingIPDeleteConfirmTitle: "Confirm Floating IP Release",
    floatingIPDeleteConfirmText: "Are you sure, you want to release selected floating IPs? Selected floating IPs",
    floatingIPDeleteActionTitle: "Release Floating IP",
    associatedWithFormFieldLabel: "Associated With",
    mappedLocalIPSortFieldLabel: "Mapped IP Address",
    portStatusFormFieldLabel: "Port Status",
    addFloatingIPDialogTitle: "Request Floating IP",
    submenuPortForwardings: "Port Forwardings",
    noPortForwardingsNoteText: "No Port Forwardings",
    tcpFormFieldLabel: "TCP",
    udpFormFieldLabel: "UDP",
    startInternalPortFormFieldLabel: "Start Internal Port",
    endInternalPortFormFieldLabel: "End Internal Port",
    startExternalPortFormFieldLabel: "Start External Port",
    endExternalPortFormFieldLabel: "End External Port",
    createPortForwardingActionTitle: "Creat Port Forwarding",
    internalIPAddressFormFieldLabel: "Internal IP Address",
    protocolFormFieldLabel: "Protocol",
    disassociateFloatingIPActionTitle: "Disassociate Floating IP",
    associateFloatingIPActionTitle: "Associate Floating IP",
    enableSNatFormFieldLabel: "Enable SNAT",
    externalFixedIPFormFieldLabel: "External Fixed IP",
    routerDeleteConfirmText: "Are you sure, you want to delete selected routers? This action can not be reverted. Selected routers",
    routerDeleteConfirmTitle:"Confirm Router Delete",
    routerDeleteActionTitle: "Delete Router",
    addRouterDialogTitle: "Add Router",
    staticRouteFormFieldLabel: "Static Route",
    addStaticRouteFormFieldLabel: "Add Static Route",
    changeAdminStateActionTitle: "Change Admin State",
    updateRouterAdminStateActionTitle: "Change Router Admin State",
    routerAdminStateFormFieldLabel: "Admin State",
    upFormFieldLabel: "Up",
    downFormFieldLabel: "Down",
    routerInterfacesTitleText: "Router Interfaces",
    submenuStaticRoutes: "Static Routes",
    staticRoutesTitleText: "Static Routes",
    routerUpdateActionTitle: "Update Router",
    routerUpdateStaticRoutesActionTitle: "Update Static Routes",
    updateRouterActionTitle: "Update Router Details",
    addRouterInterfaceActionTitle: "Add Router Interface",
    statefulFormFieldLabel: "Stateful",
    securityGroupsNumberFormFieldLabel: "Security Groups",
    icmpFormFieldLabel: "ICMP",
    anyFormFieldLabel: "ANY",
    otherProtocolFormFieldLabel: "Other Protocol",
    protocolNumberFormFieldLabel: "Protocol Number",
    fromPortFormFieldLabel: "From Port",
    toPortFormFieldLabel: "To Port",
    remoteFormFieldLabel: "Remote",
    addressGroupFormFieldLabel: "Address Group",
    ipPrefixFormFieldLabel: "IP Prefix",
    remoteSecurityGroupFormFieldLabel: "Remote Security Group",
    remoteAddressGroupFormFieldLabel: "Remote Address Group",
    usedInDefSGFormFieldLabel: "Used In Default Security Group",
    usedInNonDefSGFormFieldLabel: "Used In None Defaul Security Groups",
    portsFormFieldLabel: "Ports",
    addSecurityGroupDialogTitle: "Add Security Group",
    securityGroupsTabButtonText: "Security Groups",
    addressGroupsTabButtonText: "Address Groups",
    defaultRulesTabButtonText: "Default Rules",
    submenuSecGroupRules: "Rules",
    securityGroupDeleteConfirmTitle: "Confirm Security Group Delete",
    securityGroupDeleteConfirmText: "Are you sure, you want to delete selected security groups? This action can not be reverted. Selected security groups",
    securityGroupDeleteActionTitle: "Delete Security Group",
    changeStatefulStateTitle: "Change Stateful State",
    changeStatefulStateActionTitle: "Change Stateful State",
    statefuleFormFieldLabel: "Stateful",
    securityGroupRulesTitleText: "Security Group Rules",
    ethertypeFormFieldLabel: "Ethertype",
    securityGroupUpdateActionTitle: "Update security group",
    updateSecurityGroupActionTitle: "Update Security Group",
    addSecGroupRuleActionTitle: "Add rule",
    addSecurityGroupRuleActionTitle: "Add Security Group Rule",
    ingressFormFieldLabel: "Ingress",
    egressFormFieldLabel: "Egress",
    icmpTypeFormFieldLabel: "ICMP Type",
    icmpCodeFormFieldLabel: "ICMP Code",
    invalidPortRangeErrorText: "Invalid Port Range Provided",
    addAddressGroupDialogTitle: "Add Address Group",
    addAddressFormFieldLabel: "Add Address",
    addressFormFieldLabel: "Address CIDR",
    addressGroupDeleteConfirmTitle: "Confirm Address Group Delete",
    addressGroupDeleteConfirmText: "Are you sure, you want to delete selected address groups?",
    addressGroupUpdateActionTitle: "Update Address Group",
    addressRemoveActionTitle: "Remove Address",
    removeAddressFromAGActionTitle: "Remove Address from Group",
    addressGroupDeleteActionTitle: "Delete Address Group",
    parentSecurityGroupFormFieldLabel: "PARENT",
    addDefaultRuleDialogTitle: "Add New Default Rule",
    defaultRuleDeleteConfirmText: "Are you sure, you want to delete selected rules?",
    defaultRuleDeleteConfirmTitle: "Confirm Delete Default Rule",
    defaultRuleDeleteActionTitle: "Delete default rule",
    ingressFWPolicyFormFieldLabel: "Ingress Policy",
    egressFWPolicyFormFieldLabel: "Egress Policy",
    portsCountFormFieldLabel: "Ports Count",
    addFirewallGroupDialogTitle: "Add Firewall Group",
    firewallGroupsTabButtonText: "Firewall Groups",
    firewallPoliciesTabButtonText: "Firewall Policies",
    firewallRulesTabButtonText: "Firewall Rules",
    firewallGroupDeleteConfirmText: "Are you sure you want to delete selected firewall groups?",
    firewallGroupDeleteConfirmTitle: "Delete Firewall Group",
    firewallGroupDeleteActionTitle: "Delete Firewall Group",
    submenuFWIngressRules: "Ingress Rules",
    submenuFWEgressRules: "Egress Rules",
    submenuFWPorts: "Ports",
    updateFirewallGroupActionTitle: "Update Firewall Group",
    firewallGroupUpdateActionTitle: "Update FW Group",
    fwSourceFormFieldLabel: "Source",
    fwDestinationFormFieldLabel: "Destination",
    actionFormFieldLabel: "Action",
    sourcePortsFormFieldLabel: "Source Ports",
    destinationPortsFormFieldLabel: "Destination Ports",
    firewallGroupIngressRulesTitleText: "Firewall Group Ingress Rules",
    firewallGroupEgressRulesTitleText: "Firewall Group Egress Rules",
    fwGroupAppliedPortsTitleText: "Ports Using Firewall Group",
    adminStateChangeActionTitle: "Admin State Update",
    shareStateChangeActionTitle: "Shared State Update",
    changeAdminStateTitle: "Change Admin State",
    changeSharedStateTitle: "Change Shared State",
    updateFWGroupPoliciesActionTitle: "Update FW Group Policies",
    noPolicyFormFieldLabel: "No Policy",
    fwGroupAddToPortActionTitle: "Apply FW Group to Port",
    auditedFormFieldLabel: "Audited",
    changeAuditedStateTitle: "Change Audited State",
    auditedStateChangeActionTitle: "Change Audited State",
    auditedStateFormFieldLabel: "Audited",
    fwPolicypDeleteConfirmTitle: "Confirm FW Policy Delete",
    fwPolicyDeleteConfirmText: "Are you sure, you want to delete seleted policies? Selected policies",
    firewallPolicyDeleteActionTitle: "Delete FW Policy",
    addFirewallPolicyDialogTitle: "Add FW Policy",
    submenuFWPolicyRules: "Policy Rules",
    firewallPolicyRulesTitleText: "Firewall Policy Rules",
    firewallPolicyUpdateActionTitle: "Update FW Policy",
    updateFirewallPolicyActionTitle: "Update FW Policy Details",
    fwPolicyAddRuleActionTitle: "Add FW Rules to Policy",
    firewallPoicyFormFieldLabel: "FW Policy",
    allowActionFormFieldLabel: "Allow",
    denyActionFormFieldLabel: "Deny",
    sourceAddressFormFieldLabel: "Source Address",
    sourceFWGroupFormFieldLabel: "Source Firewall Group",
    destinationAddressFormFieldLabel: "Destination Address",
    destinationFWGroupFormFieldLabel: "Destination Firewall Group",
    sourcePortFormFieldLabel: "Source Port",
    destinationPortFormFieldLabel: "Destination Port",
    addFirewallRuleDialogTitle: "Add Firewall Rule",
    firewallGroupFormFieldLabel: "Firewall Group",
    sourceFirewallGroupFormFieldLabel: "Source Firewall Group",
    sourceFromPortFormFieldLabel: "Source From Port",
    sourceToPortFormFieldLabel: "Source To Port",
    destinationTypeFormFieldLabel: "Destination",
    destinationFirewallGroupFormFieldLabel: "Destination Firewall Group",
    destinationIPAddressFormFieldLabel: "Destination IP Address",
    destinationFromPortFormFieldLabel: "Destination From Port",
    destinationToPortFormFieldLabel: "Destination To Port",
    allowFormFieldLabel: "Allow",
    denyFormFieldLabel: "Deny",
    sourceIPAddressFormFieldLabel: "Source IP Address",
    enableDisableRuleTitle: "Enable/Disable Rule",
    enableDisableRuleActionTitle: "Enable/Disable Rule",
    firewallRuleDeleteActionTitle: "Delete Rule",
    firewallRuleDeleteConfirmTitle: "Confirm Rule Delete",
    firewallRuleDeleteConfirmText: "Are you sure, you want to delete selected firewall rules?",
    firewallRuleUpdateActionTitle: "Update FW Rule",
    updateFirewallRuleActionTitle: "Update Firewall Rule",
    fwGRAddPortNoteText: "Note, Firewall Group can be applied only to router ports",
    ikeVersionFormFieldLabel: "IKE Version",
    authAlgorithmFormFieldLabel: "Auth Algorithm",
    encryptionAlgorithmFormFieldLabel: "Encryption Algorithm",
    pfsGroupFormFieldLabel: "PFS Group",
    negotiationModeFormFieldLabel: "Negotiation Mode",
    lifetimeFormFieldLabel: "Lifetime",
    transformProtocolFormFieldLabel: "Transform Protocol",
    encapsulationModeFormFieldLabel: "Encapsulation Mode",
    mainModeFormFieldLabel: "Main",
    agrassiveModeFormFieldLabel: "Aggrasive",
    externalIPv4AddressFormFieldLabel: "External IPv4 Address",
    externalIPv6AddressFormFieldLabel: "External IPv6 Address",
    endpointsFormFieldLabel: "Endpoints",
    endpointFormFieldLabel: "Endpoint",
    peerAddressFormFieldLabel: "Peer Address",
    ikePolicyFormFieldLabel: "IKE Policy",
    ipsecPolicyFormFieldLabel: "IPsec Policy",
    vpnServiceFormFieldLabel: "VPN Service",
    localEndpointGroupFormFieldLabel: "Local Endpoint Group",
    localIDFormFieldLabel: "Local ID",
    peerIDFormFieldLabel: "Peer ID",
    pskFormFieldLabel: "PSK",
    initiatorFormFieldLabel: "Initiator",
    responseOnlyFormFieldLable: "Response Only",
    dpdActionFormFieldLabel: "DPD Action",
    holdActionFormFieldLable: "Hold",
    clearActionFormFieldLable: "Clear",
    restartActionFormFieldLable: "Restart",
    disabledActionFormFieldLable: "Disabled",
    restartByPeerActionFormFieldLable: "Restart by peer",
    ikePolicyDeleteConfirmTitle: "Confirm IKE Policy Delete",
    ikePolicyDeleteConfirmText: "Are you sure, you want to delete selected IKE Policies?",
    ikePolicyDeleteActionTitle: "Delete IKE Policy",
    ikePoliciesTabButtonText: "IKE Policies",
    ipsecPoliciesTabButtonText: "IPsec Policies",
    vpnServicesTabButtonText: "VPN Services",
    endpointGroupsTabButtonText: "Endpoint Groups",
    siteConnectionsTabButtonText: "Site Connections",
    addIkePolicyDialogTitle: "Add IKE Policy",
    ipsecPolicyDeleteActionTitle: "Delete IPsec Policy",
    ipsecPolicyDeleteConfirmTitle: "Confirm IPsec Policy Delete",
    ipsecPolicyDeleteConfirmText: "Are you sure, you want to delete selected IPsec Policies?",
    addIPsecPolicyDialogTitle: "Add IPsec Policy",
    vpnServiceDeleteConfirmTitle: "Confirm VPN Service Delete",
    vpnServiceDeleteConfirmText: "Are you sure, you want to delete selected VPN services?",
    vpnServiceDeleteActionTitle: "Delete VPN Service",
    addVPNServiceDialogTitle: "Add VPN Service",
    routerFormFieldLabel: "Router",
    addCidrFormFieldLabel: "Add CIDR to list",
    addVPNEndpointGroupDialogTitle: "Add Endpoint Group",
    addSubnetFormFieldLabel: "Add Subnet",
    vpnEndpointGroupDeleteConfirmTitle: "Delete Endpoint Group",
    vpnEndpointGroupDeleteConfirmText: "Are you sure you want to delete selected endpoint groups?",
    vpnEndpointGroupDeleteActionTitle: "Delete Endpoint Group",
    vpnEndpointGroupUpdateActionTitle: "Update Endpoint Group",
    updateEndpointGroupNameTitle: "Update Endpoint Group Name",
    vpnEndpointGroupUpdateNameActionTitle: "Update Name",
    updateEndpointGroupDescriptionTitle: "Update Endpoint Group Description",
    vpnEndpointGroupUpdateDescriptionActionTitle: "Update Description",
    updateIkePolicyNameTitle: "Update IKE Policy Name",
    ikePolicyUpdateNameActionTitle: "Update Name",
    ikePolicyUpdateDescriptionActionTitle: "Update Description",
    updateIkePolicyDescriptionTitle: "Update IKE Policy Description",
    ikePolicyUpdateAuthAlgActionTitle: "Update Auth Algorithm",
    updateIkePolicyAuthAlgTitle: "Update Auth Algorithm",
    updateIkePolicyEncrAlgTitle: "Update Encryption Algorithm",
    updateIkePolicyPFSGroupTitle: "Update PFS Group",
    updateIkePolicyIKEVersionTitle: "Update IKE Version",
    ikePolicyUpdateEncrAlgActionTitle: "Update Encryption Algorithm",
    ikePolicyUpdatePFSGroupActionTitle: "Update PFS Group",
    ikePolicyUpdateIKEVersionActionTitle: "Update IKE Version",
    updateIPsecPolicyEncapModeTitle: "Update Encapsulation Mode",
    ipsecPolicyUpdateEncapModeActionTitle: "Update Encapsulation Mode",
    ipsecPolicyUpdateTransProtoActionTitle: "Update Transform Protocol",
    ipsecPolicyUpdateNameActionTitle: "Update Name",
    ipsecPolicyUpdateDescriptionActionTitle: "Update Description",
    ipsecPolicyUpdateAuthAlgActionTitle: "Update Auth Algorithm",
    ipsecPolicyUpdateEncrAlgActionTitle: "Update Encryption Algorithm",
    ipsecPolicyUpdatePFSGroupActionTitle: "Update PFS Group",
    updateIPsecPolicyNameTitle: "Update IPsec Policy Name",
    updateIPsecPolicyDescriptionTitle: "Update IPsec Policy Description",
    updateIPsecPolicyAuthAlgTitle: "Update IPsec Policy Auth Algorithm",
    updateIPsecPolicyEncrAlgTitle: "Update IPsec Policy Encryption Algorithm",
    updateIPsecPolicyPFSGroupTitle: "Update IPsec Policy PFs Group",
    updateVPNServiceNameTitle: "Update VPN Service Name",
    updateVPNServiceDescriptionTitle: "Update VPN Service Description",
    vpnServiceUpdateNameActionTitle: "Update Name",
    vpnServiceUpdateDescriptionActionTitle: "Update Description",
    vpnServiceUpdateAdminUpActionTitle: "Update Admin State",
    updateVPNServiceAdminUpTitle: "Update Admin State",
    bidirectionalFormFieldLable: "Bi-directional",
    peerEndpointGroupFormFieldLabel: "Peer Endpoint Group",
    addVPNSiteConnectionDialogTitle: "Add VPN Site Connection",
    peerCIDRFormFieldLabel: "Peer CIDR comma-separated (Deprecated)",
    peerCIDRsFormFieldLabel: "Peer CIDRs",
    dpdFormFieldLabel: "DPD",
    dpdIntervalFormFieldLabel: "Interval",
    dpdTimeoutFormFieldLabel: "Timeout",
    authModeFormFieldLabel: "Auth Mode",
    routeModeFormFieldLabel: "Route Mode",
    vpnSiteConnectionUpdateActionTitle: "Update Site Connection",
    vpnSiteConnectionDeleteActionTitle: "Delete Site Connection",
    vpnSiteConnectionUpdateTitle: "Update Site Connection Details",
    vpnSiteConnectionDeleteConfirmTitle: "Confirm Site Connection Delete",
    vpnSiteConnectionDeleteConfirmText: "Are you sure, you want to delete selected site connections?",
    ipModeFormFieldLabel: "IP Mode",
    translateModeChoiceFormField: "Translate (DNAT)",
    passthroughModeChoiceFormField: "Passthrough (no DNAT)",
    addVirtualIPDialogTitle: "Add Virtual IP",
    virtualIPDeleteConfirmText: "Are you sure, you want to delete selected virtual IPs?",
    virtualIPDeleteConfirmTitle: "Confirm Virtual IPs Delete",
    virtualIPDeleteActionTitle: "Delete Virtual IP",
    disassociateVirtualIPActionTitle: "Disassociate from port",
    associateVirtualIPActionTitle: "Associate with Port",
    updateVirtualIPActionTitle: "Update details",
    submenuPortAssociations: "Port Associations",
    noVirtualIPPortAssociationsText: "No Associations",
    localPortFormFieldLabel: "Local Port",
    virtualIPAssociationsTitleText: "Virtual IP Associations",
    portTypeFormFieldLabel: "Port Type",
    serverInstanceFormFieldLabel: "Server Instance",
    fixedIPAddressFormFieldLabel: "Fixed IP Address",
    vlanFormFieldLabel: "Vlan",
    vxlanFormFieldLabel: "Vxlan",
    flatFormFieldLabel: "Flat",
    greFormFieldLabel: "GRE",
    vlanIDFormFieldLabel: "Vlan ID",
    greKeyFormFieldLabel: "GRE Key",
    parentPortFormFieldLabel: "Parent Port",
    activeStatusFormChoiceText: "Active",
    downStatusFormChoiceText: "Down",
    buildStatusFormChoiceText: "Build",
    degradedStatusFormChoiceText: "Degraded",
    errorStatusFormChoiceText: "Error",
    segmentDeleteConfirmTitle: "Confirm Segment Delete",
    segmentDeleteConfirmText: "Are you sure, you want to delete selected segments?",
    segmentUpdateTitle: "Update Segment Details",
    segmentUpdateAdminStateActionTitle: "Update Admin State",
    segmentDeleteActionTitle: "Delete Segment",
    addSegmentDialogTitle: "Add New Segment",
    segmentUpdateActionTitle: "Update Segment Details",
    updateSegmentActionTitle: "Update Segment",
    trunkDeleteConfirmTitle: "Confirm Trunk Delete",
    trunkDeleteConfirmText: "Are you sure, you want to delete selected trunks?",
    addTrunkDialogTitle: "Add Trunk",
    submenuSubPorts: "Subports",
    noSubPortsNoteText: "No Subports",
    trunkUpdateActionTitle: "Update Trunk",
    subportsTableTitle: "Subports",
    segmentationTypeFormFieldLabel: "Segmentation Type",
    subPortsFormFieldLabel: "Subports",
    trunkUpdateAdminStateActionTitle: "Update Admin State",
    trunkDeleteActionTitle: "Delete Trunk",
    trunkAdminStateUpdateTitle: "Update Trunk Admin State",
    updateTrunkActionTitle: "Update Trunk Details",
    trunkAddSubportActionTitle: "Add Subport to Trunk",
    trunkRemoveSubportActionTitle: "Remove Subport from Trunk",
    unlimitedValueText: "Unlimited",
    resourceNameFormFieldLabel: "Resource Name",
    usedResourceQuotaFormFieldLabel: "Used",
    availableResourceQuotaFormFieldLabel: "Available",
    maxResourceQuotaFormFieldLabel: "Max",
    updateLimitsButtonText: "Update Limits",
    updateLimitsActionTitle: "Update Limits",
    updateNeutronLimitsActionText: "Provide project quota limits for below network resources. If you want to specify unlimited quota to any resource, provide -1 in appropriate field.",
    trunkFormFieldLabel: "Trunk",
    floatingIPFormFieldLabel: "Floating IP",
    securityGroupRuleFormFieldLabel: "Security Group Rule",
    firewallPolicyFormFieldLabel: "Firewall Policy",
    firewallRuleFormFieldLabel: "Firewall Rule",
    endpointGroupFormFieldLabel: "Endpoint Group",
    ipsecSiteConnectionFormFieldLabel: "IPsec Site Connection",
    resetLimitsDefaultButtonText: "Reset to Default",
    resetToDefaultLimitsConfirmTitle: "Confirm Limits Reset to Default",
    resetToDefaultLimitsConfirmText: "Are you sure, you want to reset all quota limits to system defaults?",
    resourcesQuotasLabel: 'Resources Quotas',
    updateLimitsActionText: "Provide project quota limits for below resources. If you want to specify unlimited quota to any resource, provide -1 in appropriate field.",
    hostAggregateDeleteActionTitle: "Delete Host Aggregate",
    aggregateDeleteConfirmTitle: "Confirm Host Aggregate Delete",
    AggregateDeleteConfirmText: "Are you sure, you want to delete selected host aggregates?",
    addAggregateDialogTitle: "Add New Host Aggregate",
    hostAggregatesTabButtonText: "Host Aggregates",
    availabilityZonesTabButtonText: "Availability Zones",
    hypervisorsTabButtonText: "Hypervisors",
    aggregateUpdateActionTitle: "Update Host Aggregate",
    updateAggregateActionTitle: "Update Host Aggregate",
    uuidFormFieldLabel: "UUID",
    hostsFormFieldLabel: "Hosts",
    submenuHosts: "Hosts",
    invalidAvailabilityZoneNameError: "Invalid Availability Zone name. It should not contain ':' character.",
    noHostNoteText: "No Hosts",
    aggregateAddHostActionTitle: "Add Hosts",
    addHostToAggregateActionTitle: "Add Hosts to Aggregate",
    addHostToAggregateActionText: "Selecte host(s) below to add to current host aggregate",
    aggregateMetadataUpdateActionTitle: "Update Host Aggregate Metadata",
    availableFormFieldLabel: "Available",
    hypervisorTypeFormFieldLabel: "Hypervisor Type",
    hostIPFormFieldLabel: "Host IP",
    serverInstancesFormFieldLabel: "Server Instances",
    runningVMsFormFieldLabel: "Running VMs",
    hypervisorInstancesDetailsDialogTitle: "Hypervisor Instances",
    flavorDeleteConfirmTitle: "Confirm Flavor Delete",
    flavorDeleteConfirmText: "Are you sure, you want to delete selected flavors? This operation can not be reverted. Selected flavors",
    flavorMetadataUpdateActionTitle: "Update Flavor Exta Specs",
    updateFlavorActionTitle: "Update Flavor",
    flavorUpdateActionTitle: "Update Flavor",
    serverCreateTitle: "Create New Server Instance",
    generalInfoSectionTitle: "General Info",
    bootSourceSectionTitle: "Boot Source",
    flavorSectionTitle: "Flavor", 
    storageSectionTitle: "Storage",
    networkSectionTitle: "Network",
    securitySectionTitle: "Security",
    keyPairSectionTitle: "Keypair",
    createNewVolumeFormFieldLabel: "Create New System Disk",
    instanceCountFormFieldLabel: "Instance Count",
    imageBootSourceFormFieldLabel: "Image",
    instanceSnapshotBootSourceFormFieldLabel: "Instance Snapshot",
    volumeBootSourceFormFieldLabel: "Volume",
    volumeSnapshotBootSourceFormFieldLabel: "Volume Snapshot",
    sizeInGBFormFieldLabel: "Size (GB)",
    sizeInBytesFormFieldLabel: "Size (Bytes)",
    dataDiskTableTitle: "Data Disk",
    systemDiskTableTitle: "System Disk",
    doNotDeleteOnTerminateFormFieldLabel: "Do not delete on server terminate",
    defaultVolumeTypeFormFieldLabel: "Default",
    addMoreDataVolumeFormFieldLabel: "Add Data Volumes",
    networkAllocationOptionsLabel: "Network Allocation",
    autoNetworkAllocationTypeTitle: "Auto",
    noneNetworkAllocationTypeTitle: "None",
    manualNetworkAllocationTypeTitle: "Manual",
    addNetworkFormFieldLabel: "Add Network",
    autoIPAllocationOptionLabel: "Automatic",
    manualIPAllocationOptionLabel: "Manual",
    ipAllocationTypeFormFieldLabel: "IP Allocation Type",
    portSectionTitle: "Port",
    addPortFormFieldLabel: "Add Network Port",
    createNewSecGroupFormFieldLabel: "Create New Security Group",
    allowSSHFormFieldLabel: "Allow SSH",
    allowHTTPFormFieldLabel: "Allow HTTP",
    allowHTTPSFormFieldLabel: "Allow HTTPS",
    noSecGroupAppliedToServerNoteText: "At least one network with enabled port security must be selected to apply security groups to it. Currently no network with required property is selected.",
    mulformSecGroupAppliedToServerNoteText: "Not all selected networks have enabled port security. You are not allowed to apply security groups to such networks.",
    selectedSecurityGroupsNoteText: "Selected Security Groups",
    selectedPortsNoteText: "Selected Ports",
    uploadPublicKeyButtonText: "Import from file",
    createNewKeypairFormFieldLabel: "Create New Keypair",
    keypairFormFieldLabel: "SSH Keypair",
    showAdvancedOptionsButtonText: "Show Advanced Options",
    hideAdvancedOptionsButtonText: "Hide Advanced Options",
    adminPasswordSectionTitle: "Admin Password",
    descriptionSectionTitle: "Description",
    availabilityZoneSectionTitle: "Availability Zone",
    diskPartitionSectionTitle: "Disk Partition",
    serverGroupSectionTitle: "Server Group",
    userDataSectionTitle: "Custom Configuration",
    metadataSectionTitle: "Metadata",
    setAdminPasswordFormFieldLabel: "Set Admin Password",
    automaticDiskPartitionOptionLabel: "Automatic",
    manualDiskPartitionOptionLabel: "Manual",
    serverGroupFormFieldLabel: "Server Group",
    customCofigurationFormFieldLabel: "Custom Configuration Script",
    uploadUserDataButtonText: "Import from file",
    bytesFormFieldLabel: "Bytes",
    providedDataSizeLabelText: "Provided data size",
    maxAllowedDataSizeLabel: "Maximum allowed",
    addMetadataButtonText: "Add Metadata",
    serverMetadataSelectActionTitle: "Apply Server Metadata",
    volumeSnapshotFormFieldLabel: "Volume Snapshot",
    emptyNetworkListErrorText: "At least one network must be selected with manual network allocation.",
    imageTypeFormFieldLabel: "Image Type",
    defaultImageTypeTitle: "Image",
    fileFormFieldLabel: "File",
    baseImageFormFieldLabel: "Base Image",
    operatingSystemFormFieldLabel: "OS",
    osVersionFormFieldLabel: "OS Version",
    usageTypeFormFieldLabel: "Usage Type",
    instanceFormFieldLabel: "Instance",
    serverCreateSnapshotActionTitle: "Create Snapshot",
    summaryLabel: "Summary",
    cpuQuotaLabel: "CPU Quota",
    memoryQuotaLabel: "Memory (GB) Quota",
    volumeQuotaLabel: "Volume Quota",
    volumeCapacityQuotaLabel: "Volume Capacity (GB) Quota",
    paymentsLabel: 'Payments',
    invoiceLabel: 'Invoice',
    invoicesLabel: 'Invoices',
    paymentMethodsLabel: 'Payment Methods',
    paymentMethodLabel: 'Payment Method',
    payLabel: 'Pay',
    paymentDetailsLabel: 'Payment Details',
    amountLabel: 'Amount',
    paymentLabel: 'Payment',
    paymentSucceededLabel: 'Payment Succeeded!',
    paymentFailedLabel: 'Payment Failed!',
    dateLabel: 'Date',
    transactionDetailsLabel: 'Transaction Details',
    invoiceIdLabel: 'Invoice ID',
    paypalDescriptionText: 'Use the PayPal button to check out with just an email address and password.',
    issueDateLabel: 'Issue Date',
    dueDateLabel: 'Due Date',
    totalLabel: 'Total',
    invoiceCommentLabel: 'Invoice Comment',
    balanceLabel: 'Balance',
    logout: 'Logout',
    drawerServiceCompute: 'Compute',
    drawerServiceStorage: 'Storage',
    drawerServiceImages: 'Images',
    drawerServiceNetwork: 'Network and Security',
    drawerServiceQuotas: 'Resources Quotas',
    drawerServiceBilling: 'Billing and Planning',
    drawerServicePayments: 'Payments',
    backups: 'Backups',
    floatingIPAddressesLabel: "Floating IP Addresses",
    vpnsLabel: "VPNs",
    firewallRulesLabel: "Firewall Rules",
    cpuCoresQuotaLabel: "CPU CORES",
    ramQuotaLabel: "RAM (GB)",
    instancesQuotaLabel: "SERVER INSTANCES",
    sshQuotaLabel: "SSH KEYS",
    volumesQuotaLabel: "VOLUMES",
    volumesCapacityQuotaLabel: "VOLUMES CAPACITY (GB)",
    snapshotsQuotaLabel: "SNAPSHOTS",
    backupsQuotaLabel: "BACKUPS",
    backupsCapacityQuotaLabel: "BACKUPS CAPACITY (GB)",
    networksQuotaLabel: "NETWORKS",
    subnetsQuotaLabel: "SUBNETS",
    portsQuotaLabel: "PORTS",
    trunksQuotaLabel: "TRUNKS",
    routersQuotaLabel: "ROUTERS",
    floatingIpsQuotaLabel: "FLOATING IPs",
    securityGroupsQuotaLabel: "SECURITY GROUPS",
    securityGroupRulesQuotaLabel: "SECURITY GROUP RULES",
    firewalGroupsQuotaLabel: "FIREWALL GROUPS",
    firewallPoliciesQuotaLabel: "FIREWALL POLICIES",
    firewallRulesQuotaLabel: "FIREWALL RULES",
    ikePoliciesQuotaLabel: "IKE POLICIES",
    ipsecPoliciesQuotaLabel: "IPSEC POLICIES",
    vpnEndpointGroupsQuotaLabel: "VPN ENDPOINT GROUPS",
    vpnServicesQuotaLabel: "VPN SERVICES",
    vpnConnectionsQuotaLabel: "VPN CONNECTIONS",
    networkingLabel: "Networking",
    vpnLabel: "VPN",
    billingLabel: 'Billling',
    planningAndCompareLabel: 'Planning & Compare',
    costBreakdownLabel: 'Cost Breakdown',
    accountTypeLabel: 'Account Type',
    shareWithOtherProjectsLabel: 'Share with other projects',
    usageQuantityLabel: 'Usage Quantity',
    gblabel: 'GB',
    mblabel: 'MB',
    hrsLabel: 'Hrs',
    maxRulesLabel: 'Max Rules',
    rulesRangeLabel: 'Rules Range',
    gbPerHourLabel: '1 GB per hour',
    rulesCountPerHourLabel: 'Rules count per hour',
    ikeAndIpsecEncryptionAlgorithmsPerHourLabel: 'IKE and IPsec encryption algorithms per hour',
    ipAddressPerHourLabel: 'IP address per hour',
    pricePerHourLabel: 'Price per hour',
    totalHoursUsedLabel: 'Total Hours Used',
    totalHoursUsedActiveLabel: 'Total Hours Used (Active)',
    totalHoursUsedOtherLabel: 'Total Hours Used (Other)',
    totalAmountLabel: 'Total Amount',
    totalSizeLabel: 'Total Size',
    janShortMonthName: 'Jan',
    febShortMonthName: 'Feb',
    marShortMonthName: 'Mar',
    aprShortMonthName: 'Apr',
    mayShortMonthName: 'May',
    junShortMonthName: 'Jun',
    julShortMonthName: 'Jul',
    augShortMonthName: 'Aug',
    sepShortMonthName: 'Sep',
    octShortMonthName: 'Oct',
    novShortMonthName: 'Nov',
    decShortMonthName: 'Dec',
    compareCostsLabel: 'Compare Costs',
    costPlanningLabel: 'Cost Planning',
    selectRegionLabel: 'Select Region',
    selectFlavorLabel: 'Select Flavor',
    cpusLabel: 'CPUs',
    countLabel: 'Count',
    monthlyLabel: 'Monthly',
    selectVolumeTypeLabel: 'Select Volume Type',
    ikePolicyEncryptionAlgorithmLabel: 'IKE Policy Encryption Algorithm',
    ipsecPolicyEncryptionAlgorithmLabel: 'IPsec Policy Encryption Algorithm',
    ikeEncryptionLabel: 'IKE Encryption',
    ipsecEncryptionLabel: 'IPsec Encryption',
    selectFirewallRulesRangeLabel: 'Select Firewall Rules Range',
    hourLabel: 'Hour',
    suspendedAlertLabel: 'Alert!',
    suspendedDescriptionLabel: 'Your account is suspended!',
    suspendedBalanceZeroLabel: 'Balance 0',
    suspendedYourDebtIsLabel: 'Your debt is',
    rawDataLabel: 'Raw Data',
    regionSelectorTooltipText: "Select Openstack Region",
    supportNavLink: "Support",
    customerSupportNavLink: "Customer Support",
    customersNotificationsHeaderTitle: "Customers Notifications",
    issueReportAlertHeaderTitle: "Issue Report Notifications",
    projectRequestAlertHeaderTitle: "Project Request Notifications",
    limitsUpdateAlertHeaderTitle: "Limits Update Notification",
    reportAnIssue: "Report an Issue",
    message: "Message",
    send: "Send",
    yourMessageHasBeenSent: "Your message has been sent",
    anErrorOccured: "An error occured",
    requestNewProject: "Request New Project",
    requestNewProjectText: "If you would like to get additional project, please click on 'Send Request' button and your administrator will get notification about this.",
    sendRequest: "Send Request",
    requestNewProjectSuccessText: "Your request has been sent to administrator",
    priceCalculator: "Price Calculator",
    
    // --------------------- 18.12.2024 ------------------------------------------

    submenuZones: "Zones",
    submenuPools: "Pools",
    zoneCreateSuccessResponseMessage: "DNS Zone successfully created!",
    zoneUpdateSuccessResponseMessage: "DNS Zone successfully updated!",
    zoneDeleteSuccessResponseMessage: "DNS Zone successfully deleted!",
    actionInProgressFormFieldLabel: "Action in Progress",
    ttlFormFieldLabel: "TTL",
    formValuePrimary: "PRIMARY",
    formValueSecondary: "SECONDARY",
    formValueActive: "Active",
    addZoneDialogTitle: "Add New DNS Zone",
    addZoneDialogDescriptionText: "Provide new DNS zone name, which must be ended with '.'",
    addDNSMasterFormFieldLabel: "Add Masters",
    mastersFormFieldLabel: "Masters",
    masterFormFieldLabel: "Master",
    attributesFormFieldLabel: "Attributes",
    formValuePending: "Pending",
    formValueError: "Error",
    zoneDeleteActionLabel: "Delete Zones",
    zoneDeleteConfirmTitle: "Zone Delete Confirm",
    zoneDeleteConfirmText: "Are you sure, you want to delete selected zones? This action can not be reverted. Selected zones",
    submenuZoneAttributes: "Attributes",
    submenuZoneNameservers: "Nameservers",
    submenuZoneRecordset: "Recordsets",
    priorityFormFieldLabel: "Priority",
    serialNumberFormFieldLabel: "Serial Number",
    versionFormFieldLabel: "Version",
    transferedAtFormFieldLabel: "Transfered at",
    zoneUpdateActionTitle: "Update zone",
    updateZoneActionTitle: "Update Zone Details",
    noNameserversNoteText: "No Nameservers",
    noRecordsetNoteText: "No Recordset",
    zoneRecordsetsTitleText: "Zone Recordsets",
    recordFormFieldLabel: "Record",
    zoneRecordDeleteConfirmTitle: "Confirm Zone Recordset Delete",
    zoneRecordDeleteConfirmText: "Are you sure you want to delete selected zone recordset",
    updateZoneRecordActionTitle: "Update Zone Recordset",
    addZoneRecordFormFieldLabel: "Add Record",
    zoneRecordsetCreateActionTitle: "Create New Recordset",
    formValueRecordsetAType: "A - Address record",
    formValueRecordsetAAAAType: "AAAA - IPv6 address record",
    formValueRecordsetCNAMEType: "CNAME - Canonical name record",
    formValueRecordsetMXType: "MX - exchange record",
    formValueRecordsetNSType: "NS - Name server",
    formValueRecordsetPTRType: "PTR - Pointer record",
    formValueRecordsetSPFType: "SPF - Sender Policy Framework",
    formValueRecordsetSRVType: "SRV - Service location",
    formValueRecordsetSSHFPType: "SSHFP - SSH Public Key Fingerprint",
    formValueRecordsetTXTType: "TXT - Text record",
    formValueRecordsetCAAType: "CAA - Certificate Authority Authorization record",
    createZoneRecordsetActionTitle: "Create New Recordset",
    addZoneRecDialogDescriptionText: "Provide new zone recordset name, which must be ended with '.'",
    zoneExportActionTitle: "Export zone",
    successZoneExportTitle: "Zone Export Success!",
    successZoneExportMessage: "Selected zone successfully exported. Click on zone in table to see exported file location.",
    noExportsNoteText: "No Exports",
    submenuZoneExports: "Exports",
    locationFormFieldLabel: "Location",
    zoneExportDataDetailsTitle: "Zone Export Data Details",
    zoneExportDeleteConfirmTitle: "Confirm Zone Export Delete",
    zoneExportDeleteConfirmText: "Are you sure you want to delete selected zone export",
    importZoneButtonText: "Import Zone",
    zoneImportTitleText: "Import Zone",
    zoneImportMessageText: "Copy and paste zone content to import.",
    zoneImportContentFormFieldLable: "Zone Import Content",
    submenuPTRRecords: "PTR Records",
    submenuFloaringIPs: "Floating IPs",
    ptrUnsetActionLabel: "Unset PTR Record",
    ptrUnsetConfirmTitle: "PTR Record Unset",
    ptrUnsetConfirmText: "Are you sure you want to unset selected PTR records",
    ptrSetActionLabel: "Set PTR Record",
    nsRecordsFormFieldLabel: "NS Records",
    lastHeartbeatFormFieldLabel: "Last Heartbeat",
    submenuServiceStatus: "Service Status",
    serviceNameFormFieldLabel: "Service Name",
    capabilitiesFormFieldLabel: "Capabilities",
    statsFormFieldLabel: "Stats",
    tldCreateSuccessResponseMessage: "Top-Level Domain Created!",
    tldUpdateSuccessResponseMessage: "Top-Level Domain Updated!",
    tldDeleteSuccessResponseMessage: "Top-Level Domain Deleted!",
    tldDeleteActionLabel: "Delete TLD",
    addTLDDialogTitle: "Add New Top Level Domain",
    tldDeleteConfirmTitle: "Confirm Top Level Domain Delete",
    tldDeleteConfirmText: "Are you sure, you want to delete selected top level domain? This action can not be reverted. Selected top level domains",
    tldUpdateActionLabel: "Update TLD",
    updateTLDActionTitle: "Update Top Level Domain Details",
    tsigkeyCreateSuccessResponseMessage: "Transaction Signature Key Created!",
    tsigkeyUpdateSuccessResponseMessage: "Transaction Signature Key Updated!",
    tsigkeyDeleteSuccessResponseMessage: "Transaction Signature Key Deleted!",
    tsigkeyDeleteActionLabel: "Delete TSIG Key",
    tsigkeyUpdateActionLabel: "Update TSIG Key",
    tsigkeyDeleteConfirmTitle: "Confirm TSIG Key Delete",
    tsigkeyDeleteConfirmText: "Are you sure, you want to delete selected TSIG Key? This action can not be reverted. Selected TSIG keys",
    updateTsigkeyActionTitle: "Update TSIG Key",
    addTsigkeyDialogTitle: "Add New TSIG Key",
    submenuTsigkeys: "TSIG Keys",
    algorithmFormFieldLabel: "Algorithm",
    resourceormFieldLabel: "Resource",
    blacklistCreateSuccessResponseMessage: "Blacklist Created!",
    blacklistUpdateSuccessResponseMessage: "Blacklist Updated!",
    blacklistDeleteSuccessResponseMessage: "Blacklist Deleted!",
    blacklistDeleteActionLabel: "Delete blacklist",
    blacklistUpdateActionLabel: "Update blacklist",
    updateBlacklistActionTitle: "Update Blacklist Details",
    blacklistDeleteConfirmTitle: "Confirm Blacklist Delete",
    blacklistDeleteConfirmText: "Are you syre, you want to delete selected blacklist? This action can not be reverted. Selected blacklists",
    submenuBlacklists: "Blacklists",
    submenuTLDs: "Top Level Domains",
    patternFormFieldLabel: "Pattern",
    addBlacklistDialogTitle: "Add New DNS Blacklist",
    addBlacklistDialogMessage: "Blacklist pattern can be any regular expression matching desired domains/subdomains. F.e. ^([A-Za-z0-9_\\-]+\\.)*example\\.com\\.$",
    apiExportSizeFormFieldLabel: "API Export Size",
    recordsetRecordsFormFieldLabel: "Recordset Records",
    zoneRecordsFormFieldLabel: "Zone Records",
    zoneRecordsetsFormFieldLabel: "Zone Recordsets",
    zonesFormFieldLabel: "Zones",
    quotaLimitsFormFieldLabel: "Quota Limits",
    updateDNSLimitsActionText: "Please, provide DNS desired quota limits below and submit.",
    drawerServiceDns: 'DNS',
    submenuReverseDns: "Reverse DNS",
    apiExportSizeQuotaLabel: "API EXPORT SIZE",
    recordsetRecordsQuotaLabel: "RECORDSET RECORDS",
    zoneRecordsQuotaLabel: "ZONE RECORDS",
    ZoneRecordsetsQuotaLabel: "ZONE RECORDSETS",
    zonesQuotaLabel: "ZONES",

    // --------------------- 28.02.2025 ------------------------------------------

    submenuBackupClients: "Backup Clients",
    submenuBackupJobs: "Backup Jobs",
    submenuActions: "Actions",
    submenuSessions: "Sessions",
    submenuBackupPolicies: "Backup Policies",
    backupServiceNavLink: "Backup Automation",
    backupServiceEnableDescriptionText: "It seems that your backup automation service is not enabled yet. In order to enable the service, we need to generate your application credentials, which will be used by backup automation service to fetch data from your cloud provider, and start the backup process automatically according to your backup policies. This application credentials will be limited only to those resources, which are required to get servers/volumes information and create or delete backups in Block Storage service. No other data or actions will be allowed to this application credentials. You may regenerate application credentials at any time in the future.",
    backupServiceEnableButtonText: "Enable Service",
    addBackupPolicyDialogTitle: "Create New Backup Policy",
    addBackupPolicyDialogDescriptionText: "You can create as many backup policies as required. All backup policies will be tracked and executed according to policy scheduling. Backup Automation service will create new backup item in OpenStack Block Storage and if required by policy will remove all unnecessary old backup items.",
    policyNameFormFieldLabel: "Policy Name",
    customCronStringFormFieldLabel: "Custom Cron String",
    cronStringFormFieldLabel: "Cron String",
    frequencyFormFieldLabel: "Frequency",
    dailyFormChoiceLabel: "Daily",
    weeklyFormChoiceLabel: "Weekly",
    monthlyFormChoiceLabel: "Monthly",
    yearlyFormChoiceLabel: "Yearly",
    everyFormFieldLabel: "Every",
    onDateFormFieldLabel: "On",
    onMonthFormFieldLabel: "On",
    atTheFormFieldLabel: "At the",
    startingAtFormFieldLabel: "Starting at",
    retentionTypeFormFieldLabel: "Retention Type",
    countFormFieldLabel: "Count",
    firstDayFormChoiceLabel: "First Day",
    lastDayFormChoiceLabel: "Last Day",
    keepFormFieldLabel: "Keep",
    retentionTimeFormFieldLabel: "Retention Time",
    targetTypeFormFieldLabel: "Target Object",
    policyOperatorFormFieldLabel: "Rules Applying Operator",
    dayFormFieldLabel: "Day(s)",
    monthFormFieldLabel: "Month(s)",
    yearFormFieldLabel: "Year(s)",
    targetMatchingPolicyFormFieldLabel: "Target Matching Rule",
    targetMatchingRulesFormFieldLabel: "Target Matching Rules",
    addTargetMatchingPolicyFormFieldLabel: "Add Target Matching Rule",
    targetFieldFormFieldLabel: "Target Parameter",
    matchingRuleFormFieldLabel: "Matching Rule",
    equalsFormFieldLabel: "Equals",
    containsFormFieldLabel: "Contains",
    startsWithFormFieldLabel: "Starts with",
    endsWithFormFieldLabel: "Ends with",
    regexFormFieldLabel: "Regex",
    mondayFormFieldLabel: "Mon",
    tuesdayFormFieldLabel: "Tue",
    wednesdayFormFieldLabel: "Wed",
    thursdayFormFieldLabel: "Thu",
    fridayFormFieldLabel: "Fri",
    saturdayFormFieldLabel: "Sat",
    sundayFormFieldLabel: "Sun",
    retentionFormTitle: "Retention",
    policyFieldKeyHelpText: "Note, that only 'Value' field will be checked according to selected matching rule. 'Key' field must be equal to actual metadata key.",
    invalidCronStringErrorText: "Invalid cron string!",
    invalidRetentionCountErrorText: "Retention count must be greater than 0.",
    emptyWeekdaysListErrorText: "At least, one weekday must be selected!",
    nextRunFormFieldLabel: "Next Run (UTC)",
    retentionCountFormFieldLabel: "Retention Count",
    backupPolicyAddSuccessTitle: "Backup Policy Added Successfully",
    backupPolicyAddSuccessMessage: "Backup Policy was successfully created. You can see details in backup policies table.",
    backupPolicyAddErrorTitle: "Backup Policy Create Error",
    backupPolicyAddErrorMessage: "Failed to create backup policy. See details below.",
    backupPolicyDeleteActionTitle: "Delete Backup Policies",
    backupPolicyDeleteConfirmTitle: "Confirm Backup Policy Delete",
    backupPolicyDeleteConfirmText: "Are you sure, you want to delete selected backup policies?",
    submenuMatchingRules: "Matching Rules",
    fieldFormFieldLabel: "Field",
    backupPolicyMatchingRulesTitleText: "Resource Matching Rules",
    weekdaysFormFieldLabel: "Weekdays",
    janMonthFormFieldLabel: "Jan",
    febMonthFormFieldLabel: "Feb",
    marMonthFormFieldLabel: "Mar",
    AprMonthFormFieldLabel: "Apr",
    mayMonthFormFieldLabel: "May",
    junMonthFormFieldLabel: "Jun",
    julMonthFormFieldLabel: "Jul",
    augMonthFormFieldLabel: "Aug",
    sepMonthFormFieldLabel: "Sep",
    octMonthFormFieldLabel: "Oct",
    novMonthFormFieldLabel: "Nov",
    decMonthFormFieldLabel: "Dec",
    backupPolicyUpdateActionTitle: "Update policy",
    backupPolicyRetentionUpdateActionTitle: "Update policy retention",
    backupPolicySchedulerUpdateActionTitle: "Update policy scheduling",
    backupPolicyRulesUpdateActionTitle: "Update matching rules",
    backupPolicyUpdateSuccessTitle: "Backup Policy Updated Successfully",
    backupPolicyUpdateSuccessMessage: "Backup Policy was successfully updated. You can see details in backup policy card.",
    backupPolicyUpdateErrorTitle: "Backup Policy Update Failed",
    backupPolicyUpdateErrorMessage: "Failed to update backup policy. See details below.",
    updateBackupPolicyActionTitle: "Update Backup Policy",
    updateBackupPolicyRetentionActionTitle: "Update Policy Retention Settings",
    updateBackupPolicySchedulerActionTitle: "Update Policy Scheduling Settings",
    updateBackupPolicyRulesActionTitle: "Update Policy Matching Rules",
    lastDayFormFieldLabel: "Last Day",
    updateNotificationSettingsActionTitle: "Update Notification Settings",
    backupAutomationNotificationSettingsTitle: "Notification Settings",
    updateNotificationSettingsActionMessage: "Please, provide comma separated email addresses, which should receive email notifications about failed backups and/or other notifications from Backup Automation System. Also, you may enable/disable email notifications by checking corresponding checkbox below.",
    emailsFormFieldLabel: "Email List",
    resourceFormFieldLabel: "Resource",
}