export const languageServiceURLs = {
  getTranslatedItems: "content",
  getLangConfigs: "clients",
}

export const coreServiceURLs = {
  getSubscriptionPage: "subscribe",
  getClientInitConfig: "client-init-config",
  getClientConfig: "client-config",
  getOpenStackIssueReport: "openstack-issue-report",
  getOpenStackProjectRequest: "openstack-project-request",
  getOpenStackUserSignUp: "openstack-user-signup",
  getOpenStackLimitsUpdate: "openstack-limits-update",
}

export const openstackBackendURLs = {
  getIdentityKeystoneUrls: "identity-keystone-urls",
}

export const billingUrls = {
  getAccountConfigs: 'account-configs',
  getAccountConfigsPublic: 'account-configs-public',
  getProjectDeposits: 'project-deposits',
  getDepositRefill: 'deposit-refill',
  getPostpaidInvoices: 'postpaid-invoices',
  getResourceChargesSummary: 'resource-charges-summary',
  getServiceCharges: 'service-charges',
  getResourceCharges: '-charges',
  getResourceGraph: '-charges-time-graph',
  getCompareMonthCharges: 'compare-month-charges',
  getServicesMonthBreakdown: 'services-month-breakdown',
  getAccountPrices: 'account-prices',
}

export const backupServiceURLs = {
  accessRulesEP: "access-rules",
  userCredentialsEP: "user-credentials",
  backupPoliciesEP: "backup-policies",
  notificationSettingsEP: "notification-settings",
}