import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled, useTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import NavBar from './NavBar';
import { BiServer } from "react-icons/bi";
import { GrStorage } from "react-icons/gr";
import { LuNetwork } from "react-icons/lu";
import { IoCopyOutline } from "react-icons/io5";
import { IoStatsChart } from "react-icons/io5";
import { BsGraphUp } from "react-icons/bs";
import { BsCreditCard2Back } from "react-icons/bs";
import { CgMenuGridO } from "react-icons/cg";
import { LiaHandsHelpingSolid } from "react-icons/lia";
import { setDrawerOpened } from '../../store/reducers/drawerSlice';
import constants from '../../config/constants';
import { billingRequest } from '../../_network/openstack_request';
import { coreServiceURLs, billingUrls } from '../../_network/apiUrls';
import { setBillingAccountConfig, setBillingDeposit, setHasBilling } from '../../store/reducers/profileSlice';
import request from '../../_network/request';
import { setPurchasedServices, setPurchasedServicesAll } from '../../store/reducers/openStackSlice';
import { setRegionName } from '../../store/reducers/settingsSlice';
import { CiServer } from "react-icons/ci";
import { GiHouseKeys } from "react-icons/gi";
import { GrVirtualStorage } from "react-icons/gr";
import { PiFrameCorners } from "react-icons/pi";
import { LuDatabaseBackup } from "react-icons/lu";
import { LuGroup } from "react-icons/lu";
import { AiOutlineGroup } from "react-icons/ai";
import { FaEthernet } from "react-icons/fa6";
import { TbRouteSquare2 } from "react-icons/tb";
import { MdOutlineSecurity } from "react-icons/md";
import { GiBrickWall } from "react-icons/gi";
import { MdVpnLock } from "react-icons/md";
import { BiTransfer } from "react-icons/bi";
import Tooltip from '@mui/material/Tooltip';
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowUp } from "react-icons/md";
import Popover from '@mui/material/Popover';
import { TbWorldWww } from "react-icons/tb";
import { FaRegRectangleList } from "react-icons/fa6";
import { MdSettingsBackupRestore } from "react-icons/md"
// import { GiBackForth } from "react-icons/gi";

const defaultPrimaryServiceList = [
  {
    service: 'drawerServiceCompute', 
    icon: BiServer, 
    navigation: '/compute', 
    submenu_opened: false,
    submenu: [
      {service: 'submenuServers', icon: CiServer, navigation: '/compute/instances'},
      {service: 'submenuKeypairs', icon: GiHouseKeys, navigation: '/compute/keypairs'},
    ]
  },
  {
    service: 'drawerServiceStorage', 
    icon: GrStorage, 
    navigation: '/storage',
    submenu_opened: false,
    submenu: [
      {service: 'submenuVolumes', icon: GrVirtualStorage, navigation: '/storage/volumes'},
      {service: 'submenuTransfers', icon: BiTransfer, navigation: '/storage/transfers'},
      {service: 'submenuSnapshots', icon: PiFrameCorners, navigation: '/storage/snapshots'},
      {service: 'submenuBackups', icon: LuDatabaseBackup, navigation: '/storage/backups'},
      {service: 'submenuGroups', icon: LuGroup, navigation: '/storage/volume-groups'},
      {service: 'submenuGroupSnapshots', icon: AiOutlineGroup, navigation: '/storage/group-snapshots'},
    ]
  },
  {
    service: 'drawerServiceImages',
    icon: IoCopyOutline,
    navigation: '/images'
  },
  {
    service: 'drawerServiceNetwork', 
    icon: LuNetwork, 
    navigation: '/networks',
    submenu_opened: false,
    submenu: [
      {service: 'submenuL2Networks', icon: FaEthernet, navigation: '/networks/l2-networking/networks'},
      {service: 'submenuL3Networks', icon: TbRouteSquare2, navigation: '/networks/l3-networking/subnets'},
      {service: 'submenuSecurity', icon: MdOutlineSecurity, navigation: '/networks/network-security/security-groups'},
      {service: 'submenuFWaaS', icon: GiBrickWall, navigation: '/networks/network-fwaas/firewall-groups'},
      {service: 'submenuVPNaaS', icon: MdVpnLock, navigation: '/networks/network-vpnaas/ike-policies'},
    ]
  },
  {
    service: 'drawerServiceDns', 
    icon: TbWorldWww, 
    navigation: '/dns',
    submenu_opened: false,
    submenu: [
      {service: 'submenuZones', icon: FaRegRectangleList, navigation: '/dns/zones'},
      // {service: 'submenuReverseDns', icon: GiBackForth, navigation: '/dns/reverse-dns/ptr-records'},
    ]
  },
  {
    service: 'drawerServiceQuotas',
    icon: IoStatsChart,
    navigation: '/limits'
  },
]

const secondaryServiceList = [
  {service: 'drawerServiceBilling', icon: BsGraphUp, navigation: '/billing'},
  {service: 'drawerServicePayments', icon: BsCreditCard2Back, navigation: '/payments'},
]

const supportNavList = [
  { service: 'supportNavLink', icon: LiaHandsHelpingSolid, navigation: '/support'}
]

const backupNavList = [
  { service: 'backupServiceNavLink', icon: MdSettingsBackupRestore, navigation: '/backup-automation'}
]

const drawerWidth = 270;

const listItemButtonStyle = {
  minHeight: 48,
  px: 2.5
}

const listItemIconStyle = {
  minWidth: 0,
  py: 1,
  justifyContent: 'center',
  color: 'primary.main',
  '&:hover': {
    color: 'primary.light'
  }
}

const listSubItemIconStyle = {
  minWidth: 0,
  py: 1,
  justifyContent: 'center',
  color: 'primary.main',
  '&:hover': {
    color: 'primary.light'
  }
}

const listItemTextStyle = {
  color: 'text.primary',
  '&:hover': {
    color: 'primary.main'
  }
}

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer,
    transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const refreshInterval = 900000

const CustomDrawer = (props) => {
  const isAuthenticated = useSelector(state => state.profile.isAuthenticated)
  const accessToken = useSelector(state => state.profile.access_token)
  const xAuthToken = useSelector(state => state.profile.x_auth_token)
  const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject)
  const billingAccountConfig = useSelector(state => state.profile.billingAccountConfig)
  const billingDeposit = useSelector(state => state.profile.billingDeposit)
  const hasBilling = useSelector(state => state.profile.hasBilling)
  
  const open = useSelector(state => state.drawer.drawerOpened)
  const mode = useSelector(state => state.settings.uiMode)
  const clientAccountID = useSelector(state => state.settings.clientAccountID)
  const regionName = useSelector(state => state.settings.regionName)
  const purchasedServices = useSelector(state => state.openstack.purchasedServices)
  const purchasedServicesAll = useSelector(state => state.openstack.purchasedServicesAll)
  const defaultTexts = useSelector(state => state.texts.langTexts)
  
  const [fetchDataRequired, setFetchDataRequired] = useState(1)
  const [primaryServiceList, setPrimaryServiceList] = useState([])
  const [anchorElSubmenu, setAnchorElSubmenu] = useState(null)
  const [currentPopoverItem, setCurrentPopoverItem] = useState(null)
  const [manageBackups, setManageBackups] = useState(false)

  const theme = useTheme()
  
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleDrawerToggle = () => {
    dispatch(setDrawerOpened({ drawerOpened: !open }))
  }

  const handleOpenSubmenu = (event,navigation) => {
    setCurrentPopoverItem(navigation)
    setAnchorElSubmenu(event.currentTarget)
  }
  
  const handleCloseSubmenu = () => {
    setCurrentPopoverItem(null)
    setAnchorElSubmenu(null)
  }

  const changeSubmenuOpenState = (navigation) => {
    const updated_menu = primaryServiceList.map(item => {
      if (item.navigation === navigation) {
        item.submenu_opened = !item.submenu_opened
      }
      return item
    })

    setPrimaryServiceList(updated_menu)
  }

  useEffect(() => {
    const fetchClientAccountConfig = async () => {
      const query_params = `?client_account_id=${clientAccountID}&subscription_package_group=${constants.subscription_package_group}`

      const url = `${constants.core_service_domain}/${coreServiceURLs.getClientConfig}/${query_params}`

      const request_data = {
        url: url,
        method: 'GET',
      }
      
      const response = await request(request_data)
      
      if (response.status_code && response.status_code < 400 && response.data && response.data.length > 0) {
        const client_account_config = response.data
        const identityService = client_account_config.find(item => item.config_params && item.config_params.is_identity_provider)
        const nonBackendServices = client_account_config.filter(s => s.service_type !== "backend")
        let backendServices = client_account_config.filter(s => s.service_type === "backend")

        if (regionName) {
          backendServices = backendServices.filter(s => s.config_params.region_name === regionName)
          backendServices = [identityService, ...backendServices]
        } else if (identityService.config_params.default_region !== null) {
          backendServices = backendServices.filter(s => s.config_params.region_name === identityService.config_params.default_region)
          backendServices = [identityService, ...backendServices]
          dispatch(setRegionName({ regionName: identityService.config_params.default_region }))
        }

        const purchasedRegionServices = [...backendServices, ...nonBackendServices]

        dispatch(setPurchasedServices({ purchasedServices: purchasedRegionServices }))
        dispatch(setPurchasedServicesAll({ purchasedServicesAll: client_account_config }))
      } else {
        dispatch(setPurchasedServices({ purchasedServices: [] }))
        dispatch(setPurchasedServicesAll({ purchasedServicesAll: [] }))
      }
    }

    const fetchBillingAccountConfig = async () => {
      const url = constants.billing_url + '/' + billingUrls.getAccountConfigs

      const request_data = {
        url,
        method: 'GET',
        accessToken,
        xAuthToken,
        clientAccountID,
      }
      
      const response = await billingRequest(request_data)
      
      if (response.status_code && response.status_code < 400 && response.data) {
        dispatch(setBillingAccountConfig({ billingAccountConfig: response.data }))
      } else {
        dispatch(setBillingAccountConfig({ billingAccountConfig: null }))
      }
    }

    const fetchProjectDeposits = async () => {
      const url = constants.billing_url + '/' + billingUrls.getProjectDeposits + '/' + defaultAdminProject

      const request_data = {
        url,
        method: 'GET',
        accessToken,
        xAuthToken,
        clientAccountID,
      }

      // const response = await billingRequest(request_data)
      
      // if (response.status_code && response.status_code < 400 && response.data) {
      //   dispatch(setBillingDeposit({ billingDeposit: response.data }))
      // } else {
      //   dispatch(setBillingDeposit({ billingDeposit: null }))
      // }

      billingRequest(request_data)
        .then((response) => {
          if (response.status_code && response.status_code < 400 && response.data) {
            dispatch(setBillingDeposit({ billingDeposit: response.data }));
          } else {
            dispatch(setBillingDeposit({ billingDeposit: null }));
          }
        })
        .catch(() => {
          dispatch(setBillingDeposit({ billingDeposit: null }));
        })
    }

    if (isAuthenticated) {
      fetchClientAccountConfig()
      fetchBillingAccountConfig()
      fetchProjectDeposits()
    }    
  }, [dispatch, accessToken, xAuthToken, clientAccountID, regionName, defaultAdminProject, fetchDataRequired, isAuthenticated])

  useEffect(() => {
    const dnsService = purchasedServices.find(item => item.service === 'dns_designate')
    const isDnsVisible = dnsService && dnsService.config_params && dnsService.config_params.visible_to_service && dnsService.config_params.visible_to_service.includes('custom_client_ui')
    setPrimaryServiceList(defaultPrimaryServiceList.filter(item => item.navigation !== '/dns' || isDnsVisible))
  } ,[purchasedServices])
  
  useEffect(() => {
    let has_billing = false
    
    if (purchasedServices.length > 0 && billingAccountConfig && billingAccountConfig.status && billingDeposit && billingDeposit.status) {
      const billingService = purchasedServices.find(item => item.service_type === constants.billing_service_type && item.service === constants.billing_service_name)

      if (billingService && billingAccountConfig.status === 'active' && billingDeposit.status !== 'inactive') {
        has_billing = true
      }
    }

    dispatch(setHasBilling({ hasBilling: has_billing }))
  }, [dispatch, purchasedServices, billingAccountConfig, billingDeposit])

  useEffect(() => {
    if (isAuthenticated && purchasedServicesAll.length > 0) {
      const backupService = purchasedServicesAll.filter(service => service.service === constants.config_service_backup_name)

      if (backupService && backupService.length > 0 && backupService[0].config_params && backupService[0].config_params.visible_to_service && backupService[0].config_params.visible_to_service.includes(constants.config_service_custom_admin_ui)) {
        setManageBackups(true)
      } else {
        setManageBackups(false)
      }
    }
  },[
    isAuthenticated, 
    purchasedServicesAll
  ])
  
  useEffect(() => {
    const interval = setInterval(() => {
      setFetchDataRequired(prev => prev + 1)
    }, refreshInterval)
    
    return () => clearInterval(interval)
  }, [])
  
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      <AppBar
        position="fixed" 
        open={open && isAuthenticated} 
        enableColorOnDark
        sx={{
          background: mode === 'light' ? 'white' : 'background',
          borderBottom: 0.5,
          borderColor: 'primary.main',
        }}
      >
        <Toolbar>
          {
            isAuthenticated &&
            <IconButton
              onClick={handleDrawerToggle}
              edge="start"
              sx={{
                marginRight: 5,
                color: 'primary.main',
                ...(open && { display: 'none' }),
              }}
            >
              <CgMenuGridO />
            </IconButton>
          }
          <NavBar />
        </Toolbar>
      </AppBar>

      {
        isAuthenticated && 
        <Drawer variant="permanent" open={open}>
          <DrawerHeader sx={{
              backgroundColor: mode === 'light' ? 'white' : 'background',
              borderBottom: 0.5,
              borderColor: 'primary.main',
              marginTop: '1px',
              boxShadow: open ? '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)' : 'none',
            }}
          >
            <IconButton
              onClick={handleDrawerToggle}
              edge="start"
              sx={{
                marginRight: 20,
                color: 'primary.main',
                ...(!open && { display: 'none' }),
              }}
            >
              <CgMenuGridO />
            </IconButton>
            <IconButton onClick={handleDrawerToggle}>
              {
                open ?
                <>
                  {
                    theme.direction === 'rtl' ? 
                    <ChevronRightIcon sx={{ color: 'primary.main' }} fontSize="large" /> : 
                    <ChevronLeftIcon sx={{ color: 'primary.main' }} fontSize="large" />
                  }
                </> :
                <Box sx={{ lineHeight: '0px', color: 'primary.main' }}>
                  <CgMenuGridO />
                </Box>                         
              }
            </IconButton>
          </DrawerHeader>
          <Divider />

          <Box 
            sx={{height: '100%',
              overflow: 'hidden', 
              position: 'relative'
            }}
          >
            <Box 
              sx={{
                height: '100%', 
                position: 'absolute', 
                top: 0, 
                bottom: 0, 
                left: 0, 
                right: -19, 
                overflowY: 'scroll',
                overflowX: 'hidden'
              }}
            >
              <List>
                {
                  primaryServiceList.map((item, index) => 
                  <Box key={index} sx={{position: "relative"}}>
                    <ListItem disablePadding sx={{ display: 'block' }}>
                    <ListItemButton
                      onClick={(event) => {
                        if (item.submenu) {
                          changeSubmenuOpenState(item.navigation)
                          if (!open) {
                            handleOpenSubmenu(event, item.navigation)
                          }
                        } else {
                          navigate(item.navigation)
                        }}}
                      sx={{...listItemButtonStyle, justifyContent: open ? 'initial' : 'center'}}
                    >
                      <Tooltip title={open ? "" : defaultTexts[item.service]} placement="right">
                        <ListItemIcon
                          sx={{...listItemIconStyle, mr: open ? 2 : 'auto'}}
                        >
                          <item.icon size={30} />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText 
                        primary={defaultTexts[item.service]}
                        primaryTypographyProps={{fontWeight: '400'}} 
                        sx={{ 
                          opacity: open ? 1 : 0,
                          ...listItemTextStyle
                        }} 
                      />
                      {
                        item.submenu && open &&
                        <ListItemIcon
                          sx={{...listItemIconStyle, ml: 3}}
                        >
                          {item.submenu_opened ? <MdKeyboardArrowUp size={20} /> :
                          <MdKeyboardArrowDown size={20} />}
                        </ListItemIcon>
                      }
                    </ListItemButton>
                  </ListItem>

                  {
                    open && item.submenu && item.submenu_opened && item.submenu.map((subitem, index) => 
                    <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                      <ListItemButton
                        onClick={(event) => {navigate(subitem.navigation)}}
                        sx={{...listItemButtonStyle, justifyContent: open ? 'initial' : 'center'}}
                      >
                        <Tooltip title={open ? "" : defaultTexts[subitem.service]} placement="right">
                          <ListItemIcon
                            sx={{...listSubItemIconStyle, mx: open ? 2 : 'auto'}}
                          >
                            <subitem.icon size={24} />
                          </ListItemIcon>
                        </Tooltip>
                        <ListItemText 
                          primary={defaultTexts[subitem.service]}
                          primaryTypographyProps={{fontWeight: '400'}} 
                          sx={{ 
                            opacity: open ? 1 : 0,
                            ...listItemTextStyle
                          }} 
                        />
                      </ListItemButton>
                    </ListItem>
                    )
                  }

                  {
                    !open && item.submenu && item.navigation === currentPopoverItem &&
                    <Popover 
                      open={Boolean(anchorElSubmenu)}
                      onClose={handleCloseSubmenu}
                      anchorEl={anchorElSubmenu}
                      transformOrigin={{vertical: 0, horizontal: -70}}
                    >

                    {
                      item.submenu.map((subitem, index) => 
                      <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                          onClick={(event) => {navigate(subitem.navigation); handleCloseSubmenu()}}
                          sx={{...listItemButtonStyle, justifyContent: open ? 'initial' : 'center'}}
                        >
                          <ListItemIcon
                            sx={{...listSubItemIconStyle, mr: 1}}
                          >
                            <subitem.icon size={24} />
                          </ListItemIcon>
                          <ListItemText 
                            primary={defaultTexts[subitem.service]}
                            primaryTypographyProps={{fontWeight: '400'}} 
                            sx={{ 
                              ...listItemTextStyle
                            }} 
                          />
                        </ListItemButton>
                      </ListItem>
                      )
                    }
                    </Popover>
                   }
                  </Box>
                  )
                }
              </List>

              {
                manageBackups &&
                <>
                  <Divider />
                  <List>
                    {
                      backupNavList.map((item, index) => 
                        <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                          <ListItemButton
                            onClick={(event) => {navigate(item.navigation)}}
                            sx={{
                              minHeight: 48,
                              justifyContent: open ? 'initial' : 'center',
                              px: 2.5
                            }}
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                py: 1,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                                color: 'primary.main',
                                '&:hover': {
                                  color: 'primary.light'
                                }
                              }}
                            >
                              <item.icon size={30} />
                            </ListItemIcon>
                            <ListItemText 
                              primary={defaultTexts[item.service]}
                              primaryTypographyProps={{fontWeight: '400'}} 
                              sx={{ 
                                opacity: open ? 1 : 0,
                                color: 'text.secondary',
                                '&:hover': {
                                  color: 'primary.main'
                                }
                              }} 
                            />
                          </ListItemButton>
                        </ListItem>
                      )
                    }
                  </List>
                </>
              }

              {
                hasBilling &&
                <>
                  <Divider />
                  <List>
                    {
                      hasBilling && secondaryServiceList.map((item, index) => 
                        <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                          <ListItemButton
                            onClick={(event) => {navigate(item.navigation)}}
                            sx={{
                              minHeight: 48,
                              justifyContent: open ? 'initial' : 'center',
                              px: 2.5
                            }}
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                py: 1,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                                color: 'primary.main',
                                '&:hover': {
                                  color: 'primary.light'
                                }
                              }}
                            >
                              <item.icon size={30} />
                            </ListItemIcon>
                            <ListItemText 
                              primary={defaultTexts[item.service]}
                              primaryTypographyProps={{fontWeight: '400'}} 
                              sx={{ 
                                opacity: open ? 1 : 0,
                                color: 'text.secondary',
                                '&:hover': {
                                  color: 'primary.main'
                                }
                              }} 
                            />
                          </ListItemButton>
                        </ListItem>
                      )
                    }
                  </List>
                </>
              }
              
              <Divider />
              <List>
                {
                  supportNavList.map((item, index) => 
                    <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                      <ListItemButton
                        onClick={(event) => {navigate(item.navigation)}}
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 2.5
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            py: 1,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                            color: 'primary.main',
                            '&:hover': {
                              color: 'primary.light'
                            }
                          }}
                        >
                          <item.icon size={30} />
                        </ListItemIcon>
                        <ListItemText 
                          primary={defaultTexts[item.service]}
                          primaryTypographyProps={{fontWeight: '400'}} 
                          sx={{ 
                            opacity: open ? 1 : 0,
                            color: 'text.secondary',
                            '&:hover': {
                              color: 'primary.main'
                            }
                          }} 
                        />
                      </ListItemButton>
                    </ListItem>
                  )
                }
              </List>
            </Box>
          </Box> 
        </Drawer>
      }

      <Box component="main" sx={{ flexGrow: 1, p: isAuthenticated ? 0 : 3, }}>
        <DrawerHeader />
        {props.children}
      </Box>
      
    </Box>
  )
}

export default CustomDrawer