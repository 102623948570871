import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import getDesignTokens from './themes/Theme';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import constants from './config/constants';
import AppRoutes from './AppRoutes'
import { textsReset, initialState } from './store/reducers/textsSlice';
import { setCurrentLang } from './store/reducers/settingsSlice';
import { setLangTexts } from './store/reducers/textsSlice';
import { getTranslatedText } from './lang/translator';

function App() {
  const dispatch = useDispatch();
  const defaultLang = useSelector(state => state.settings.defaultLang);
  const currentLang = useSelector(state => state.settings.currentLang);
  const defaultTexts = initialState.langTexts

  const mode = useSelector(state => state.settings.uiMode)
  const primaryColor = useSelector(state => state.settings.primaryColor)
  const secondaryColor = useSelector(state => state.settings.secondaryColor)
  
  const appTheme = createTheme(getDesignTokens(mode, primaryColor, secondaryColor))

  useEffect(() => {
    const handleLangChange = (value) => {
      dispatch(textsReset())
      if (defaultLang !== value) {
          getTranslatedText(value).then((translatedItems) => {
              let new_data = {...defaultTexts, ...translatedItems}
              dispatch(setLangTexts({langTexts: new_data}))
              dispatch(setCurrentLang({currentLang: value}));
          })
      } else {
          dispatch(setCurrentLang({currentLang: value}));
      }
    };

    const currentVersion = localStorage.getItem('appVersion');
    
    if (!currentVersion || (currentVersion && currentVersion !== constants.app_version)) {
      localStorage.setItem('appVersion', constants.app_version)
      handleLangChange(currentLang)
      
      setTimeout(() => {
        window.location.reload()
      }, 100)      
    } else {
      localStorage.setItem('appVersion', constants.app_version)
    }
  }, [
    dispatch,
    currentLang, 
    defaultLang, 
    defaultTexts
  ])
  
  return (
    <ThemeProvider theme={appTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <BrowserRouter>
          <CssBaseline enableColorScheme />
          <AppRoutes />
        </BrowserRouter>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App